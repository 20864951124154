import { RootState } from 'app/RootReducer'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { store } from 'app/store'
import { getAppFormSectionPDF } from 'features/Slice/ApplicationFormSlice'
import { ApplicationForm } from 'features/Entity/ApplicationForm'
import t from '../../components/I18N/TranslateHelpers'
import { SpinnerOnTop } from 'components/SpinnerOnTop'
import { ApplicationFormSection } from 'features/Entity/ApplicationFormSection'
import slugify from 'slugify'

type ActionButtonProps = {
  applicationForm: ApplicationForm;
  section: ApplicationFormSection;
}

export const ActionButton = ({
  applicationForm,
  section
}: ActionButtonProps) => {
  const isRequestingGetPDF = useSelector(
    (state: RootState) => state.applicationFormRdc.isRequestingGetPDF,
  );
  const userInfo = useSelector((state: RootState) => state.userRdc.userInfo);
  const [openModal, setOpenModal] = useState(false);
  const [pdfWithData, setpdfWithData] = useState(true);
  const [anonymous, setAnonymous] = useState(false);

  const buildContentPrint = async () => {
    const sectionLabel = slugify(section.label.toLowerCase());
    store.dispatch(getAppFormSectionPDF(applicationForm.uid, section.id, anonymous, !pdfWithData, sectionLabel))
    setAnonymous(false);
    setpdfWithData(true);
  }

  return (
    <>
      <div className="btn-group mr-3 downloadpdf-button">
        {
          section.isPDFDownloadable ?
            isRequestingGetPDF ? (
              <SpinnerOnTop />
            ) : (
              section.isPDFFromExternalInfo ?
              <button
                className="btn btn-orange"
                type="button" onClick={() => buildContentPrint()}
              >
                <i className="icofont icofont-download-alt"></i> {t('download_pdf')}
              </button>
              :
              <button
                className="btn btn-orange dropdown-toggle"
                type="button"
                onClick={() => setOpenModal(!openModal)}
              >
                <i className="icofont icofont-download-alt"></i> {t('download_pdf')}
              </button>
            )
          : null
        }
        {openModal && !isRequestingGetPDF ? (
          <div className="dropdown-menu show">
            {
              userInfo && userInfo.isStaff ?
                <>
                  <div>
                    <label>
                      {t('pdf_with_name')}
                    </label>
                    <div className="form-group m-checkbox-inline mb-0 custom-radio-ml">
                      <div className="radio radio-primary">
                        <input id="anonymous-true" type="radio" name="anonymous" value="true" defaultChecked onChange={() => setAnonymous(false)} />

                        <label className="mb-0" htmlFor="anonymous-true">
                          <span className="">Yes</span>
                        </label>
                      </div>
                      <div className="radio radio-primary">
                        <input id="anonymous-false" type="radio" name="anonymous" value="false" onChange={() => setAnonymous(true)} />
                        <label className="mb-0" htmlFor="anonymous-false">
                          <span className="">No</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="dropdown-divider"></div>
                </>
                : null
            }



            <label>
              {t('pdf_with_data')}
            </label>
            <div className="form-group m-checkbox-inline mb-0 custom-radio-ml">
              <div className="radio radio-primary">
                <input id="withData-true" type="radio" name="withData" value="true" defaultChecked onChange={() => setpdfWithData(true)} />

                <label className="mb-0" htmlFor="withData-true">
                  <span className="">Yes</span>
                </label>
              </div>
              <div className="radio radio-primary">
                <input id="withData-false" type="radio" name="withData" value="false" onChange={() => setpdfWithData(false)} />
                <label className="mb-0" htmlFor="withData-false">
                  <span className="">No</span>
                </label>
              </div>
            </div>
            <div className="dropdown-divider"></div>
            <button
              className="btn btn-primary-outline"
              type="button"
              onClick={() => buildContentPrint()}
            >
              {t('download_now')}
            </button>
          </div>
        ) : null}
      </div>
    </>
  )
}
