import React from "react";
import {
	Control,
	FieldValues,
	UseFormRegister,
	UseFormSetValue,
	UseFormUnregister,
	UseFormWatch,
} from "react-hook-form";
import t, { changeLanguage } from "../../../../components/I18N/TranslateHelpers";
import { GetCurrentLocale } from "../../../../components/Utils";
import {
	renderCheckbox,
	renderInputText,
	renderRadio,
} from "../../../../components/Forms/FormsComponent";
import AddNewFieldsRowDynamic from "components/Forms/AddNewFieldsRowDynamic";
import { useSelector } from "react-redux";
import { RootState } from "app/RootReducer";
import { SpinnerOnTop } from "components/SpinnerOnTop";
import { EvaluationSystemScoreTypeEnum, EvaluationSystemTypeEnum, SchoolPeriod, SchoolTranscript } from "features/Entity/School";
import { AddFieldRowData } from "features/Entity/AddFieldRowData";
import DatePickerField from "components/Forms/DatePickerField";

interface ComponentProps {
	register: UseFormRegister<FieldValues>;
	unregister: UseFormUnregister<FieldValues>;
	errors: { [x: string]: any };
	watch: UseFormWatch<FieldValues>;
	control: Control<FieldValues, any>;
	nbRender: number;
	isReadOnly: boolean;
	setValue: UseFormSetValue<FieldValues>;
}

const ApplicantSchoolTranscriptsForm: React.FC<ComponentProps> = ({
	register,
	unregister,
	errors,
	watch,
	control,
	nbRender,
	isReadOnly,
	setValue
}) => {
	const isRequesting = useSelector((state: RootState) => state.schoolRdc.isRequesting);
	const schoolTranscript = useSelector((state: RootState) => state.schoolRdc.schoolTranscript);

	React.useEffect(() => {
		const locale = GetCurrentLocale();
		changeLanguage(locale);
	}, []);

	React.useEffect(() => {
		if (schoolTranscript && schoolTranscript.evaluationSystemType !== null && schoolTranscript.evaluationSystemType !== undefined) {
			setValue("evaluationSystemType", schoolTranscript.evaluationSystemType.toString());
		}
	}, [schoolTranscript]);

	function getColumns(schoolPeriod: SchoolPeriod): AddFieldRowData[] {
		return [
			{
				type: "text",
				label: t("subjects"),
				name: "subject",
				defaultValue: ""
			},
			{
				type: "number",
				label: t("hours_week"),
				name: "hoursByWeek",
				defaultValue: ""
			},
			{
				type: "text",
				label: t("grade"),
				name: "grade",
				defaultValue: ""
			},
			{
				type: "hidden",
				label: "",
				name: "id",
				defaultValue: 0
			},
			{
				type: "hidden",
				label: "",
				name: "schoolPeriodId",
				defaultValue: schoolPeriod?.id ?? 0
			},
		];
	}
	const evaluationSystem = watch("evaluationSystemType");

	const applicantInfoSchool = (schoolTranscript: SchoolTranscript) => {
		return (
			<>
				<div className="col-lg-3 col-md-6 col-sm-6 mb-3 col-1024-6">
					<DatePickerField
						id="dateCompleteHighSchool"
						name={`dateCompleteHighSchool`}
						label={t("date_student_completed_highschool")}
						defaultValue={
							schoolTranscript?.dateCompleteHighSchool
								? new Date(schoolTranscript.dateCompleteHighSchool)
								: null
						}
						control={control}
						readonly={isReadOnly}
						required={true}
					/>
				</div>
				{renderInputText(
					t("nb_years_secondary_school"),
					"nbYearsCompleteSecondary",
					schoolTranscript.nbYearsCompleteSecondary,
					errors.nbYearsCompleteSecondary,
					register,
					true,
					"col-lg-3 col-md-6 col-sm-6 mb-3 col-1024-6",
					false,
					isReadOnly
				)}
				{renderInputText(
					t("nb_years_school_completed_before_arrival"),
					"nbYearsCompletedPriorHostCountry",
					schoolTranscript.nbYearsCompletedPriorHostCountry,
					errors.nbYearsCompletedPriorHostCountry,
					register,
					true,
					"col-lg-3 col-md-6 col-sm-6 mb-3 col-1024-6",
					false,
					isReadOnly
				)}
				{renderInputText(
					t("current_classes_level"),
					"applicantCurrentClassLevel",
					schoolTranscript.applicantCurrentClassLevel,
					errors.applicantCurrentClassLevel,
					register,
					true,
					"col-lg-3 col-md-6 col-sm-6 mb-3 col-1024-6",
					false,
					isReadOnly
				)}
			</>
		)
	}


	return (
		<React.Fragment>
			{
				isRequesting || !schoolTranscript ?
					<SpinnerOnTop />
					:
					<React.Fragment>
						<div className="row align-items-end">
							<div className="col-md-12">
								<label htmlFor="" className="form-label required">
									{t("indicates_evaluation_system_school")}
								</label>
							</div>
							<input
								type="hidden"
								id="id"
								value={schoolTranscript.id ?? 0}
								{...register("id")}
							/>
							<input
								type="hidden"
								id="applicationFormId"
								value={schoolTranscript.applicationFormId ?? 0}
								{...register("applicationFormId")}
							/>
							<input
								type="hidden"
								id="schoolId"
								value={schoolTranscript.schoolId ?? 0}
								{...register("schoolId")}
							/>
							<div className="col-md-4 col-by-results">
								<div className="form-group m-checkbox-inline mb-0 custom-radio-ml">
									{renderRadio(
										t("by_letters"),
										`evaluationSystemType`,
										EvaluationSystemTypeEnum.Letters.toString(),
										false,
										register,
										false,
										undefined,
										undefined,
										schoolTranscript.evaluationSystemType?.toString() === EvaluationSystemTypeEnum.Letters.toString(),
										isReadOnly
									)}
									{renderRadio(
										t("by_score"),
										`evaluationSystemType`,
										EvaluationSystemTypeEnum.Score.toString(),
										false,
										register,
										false,
										undefined,
										undefined,
										schoolTranscript.evaluationSystemType?.toString() === EvaluationSystemTypeEnum.Score.toString(),
										isReadOnly
									)}
									{renderRadio(
										t("other"),
										`evaluationSystemType`,
										EvaluationSystemTypeEnum.Other.toString(),
										false,
										register,
										false,
										undefined,
										undefined,
										schoolTranscript.evaluationSystemType?.toString() === EvaluationSystemTypeEnum.Other.toString(),
										isReadOnly
									)}
								</div>
							</div>
							<div className="col-md-8">
								{evaluationSystem === EvaluationSystemTypeEnum.Letters.toString() || evaluationSystem === EvaluationSystemTypeEnum.Other.toString() ? (
									renderInputText(
										t('provide_details'),
										`evaluationSystemComment`,
										schoolTranscript.evaluationSystemComment ?? "",
										errors.evaluationSystemComment,
										register,
										true,
										"d-flex provide-details",
										false,
										isReadOnly
									)
								) : evaluationSystem === EvaluationSystemTypeEnum.Score.toString() ? (
									<div className="form-group m-checkbox-inline mb-0 custom-radio-ml">
										{
											renderRadio(
												t("out_of") + 10,
												`evaluationSystemScoreType`,
												EvaluationSystemScoreTypeEnum.OutOf10.toString(),
												false,
												register,
												false,
												undefined,
												undefined,
												schoolTranscript.evaluationSystemScoreType?.toString() === EvaluationSystemScoreTypeEnum.OutOf10.toString(),
												isReadOnly
											)
										}
										{
											renderRadio(
												t("out_of") + 20,
												`evaluationSystemScoreType`,
												EvaluationSystemScoreTypeEnum.OutOf20.toString(),
												false,
												register,
												false,
												undefined,
												undefined,
												schoolTranscript.evaluationSystemScoreType?.toString() === EvaluationSystemScoreTypeEnum.OutOf20.toString(),
												isReadOnly
											)
										}
										{
											renderRadio(
												t("out_of") + 50,
												`evaluationSystemScoreType`,
												EvaluationSystemScoreTypeEnum.OutOf50.toString(),
												false,
												register,
												false,
												undefined,
												undefined,
												schoolTranscript.evaluationSystemScoreType?.toString() === EvaluationSystemScoreTypeEnum.OutOf50.toString(),
												isReadOnly
											)
										}
										{
											renderRadio(
												t("out_of") + 100,
												`evaluationSystemScoreType`,
												EvaluationSystemScoreTypeEnum.OutOf100.toString(),
												false,
												register,
												false,
												undefined,
												undefined,
												schoolTranscript.evaluationSystemScoreType?.toString() === EvaluationSystemScoreTypeEnum.OutOf100.toString(),
												isReadOnly
											)
										}
									</div>
								) : ""}
							</div>
						</div>
						{[...Array(nbRender)].map((e, index: number) => (
							<div className="col-md-12 mb-3" style={{ position: "relative" }} key={index}>
								<div className="row pt-4">
									<div className="row section-parent-legal-guardian ml-0 mr-0">
										<input
											type="hidden"
											id={`schoolPeriods[${index}].id`}
											value={schoolTranscript.schoolPeriods[index]?.id ?? 0}
											{...register(`schoolPeriods[${index}].id`)}
										/>
										<input
											type="hidden"
											id={`schoolPeriods[${index}].relSchoolApplicationFormId`}
											value={schoolTranscript.id ?? 0}
											{...register(`schoolPeriods[${index}].relSchoolApplicationFormId`)}
										/>
										<div className="col-lg-4 col-md-12 col-1024-12">
											<div className="row align-items-end d-flex">
												<div className="mb-3 col-md-8 periodLevel">
													{renderInputText(
														t("year_level"),
														`schoolPeriods[${index}].periodLevel`,
														schoolTranscript.schoolPeriods[index]?.periodLevel,
														errors.schoolPeriods && errors.schoolPeriods[index] ? errors.schoolPeriods[index].periodLevel : undefined,
														register,
														true,
														"col-md-12",
														false,
														isReadOnly
													)}
												</div>
												<div className="mb-3 col-md-4">
													{renderCheckbox(
														t("year_results"),
														`schoolPeriods[${index}].yearResults`,
														true,
														register,
														false,
														schoolTranscript.schoolPeriods[index]?.yearResults ?? false,
														``,
														isReadOnly
													)}
												</div>
											</div>
											<div className="row">
												<div className="mb-3 col-1024-6 nopadding">
													<DatePickerField
														id={`schoolPeriods[${index}].startDate`}
														name={`schoolPeriods[${index}].startDate`}
														label={t("classes_began")}
														defaultValue={
															schoolTranscript.schoolPeriods[index] && schoolTranscript.schoolPeriods[index].startDate !== null
																? new Date(schoolTranscript.schoolPeriods[index].startDate ?? new Date())
																: null
														}
														control={control}
														readonly={isReadOnly}
														required={true}
													/>
												</div>
												<div className="mb-3 col-1024-6 nopadding">
													<DatePickerField
														id={`schoolPeriods[${index}].endDate`}
														name={`schoolPeriods[${index}].endDate`}
														label={t("classes_ended")}
														defaultValue={
															schoolTranscript.schoolPeriods[index] && schoolTranscript.schoolPeriods[index].endDate !== null
																? new Date(schoolTranscript.schoolPeriods[index].endDate ?? new Date())
																: null
														}
														control={control}
														readonly={isReadOnly}
														required={true}
													/>
												</div>
											</div>
										</div>
										<AddNewFieldsRowDynamic
											disabled={isReadOnly}
											classes="col-lg-8 col-md-12 col-1024-12"
											options={getColumns(schoolTranscript.schoolPeriods[index])}
											showNumberofLines={false}
											control={control}
											register={register}
											baseData={schoolTranscript.schoolPeriods[index]?.schoolPeriodTranscripts ?? []}
											prefix={`schoolPeriods[${index}].schoolPeriodTranscripts`}
										/>
									</div>
								</div>
							</div>
						))}
						{
								applicantInfoSchool(schoolTranscript)
						}
					</React.Fragment >

			}
		</React.Fragment >
	);
};

export default ApplicantSchoolTranscriptsForm;
