import t from "../components/I18N/TranslateHelpers";
import { ToastType } from "./Toasts/ToastType";
import { toast } from 'react-toastify';
import { Answer, AnswerTemplate, Survey } from "features/Entity/Survey/Survey";
import { SurveyActionTypeEnum } from "features/Entity/Survey/SurveyActionTypeEnum";
import { SurveyWidgetDisplayMode } from "features/Entity/Survey/SurveyWidgetDisplayMode";
import { SiblingEducationLevelType, SiblingRelationshipType } from "features/Entity/Applicant";
import { ApplicationFormSectionStatusEnum } from "features/Entity/SectionStaffInfo";
import { ApplicationForm, ProgramTypeEnum } from "features/Entity/ApplicationForm";
import { AxiosError } from "axios";
import { CLS_URL, FRONT_URL, BASE_API_URL, CLS_BASE_API_URL } from "app/AppConfig";

export const GetBrowserLang = (): string => {
  return 'en';
}

export const GetCurrentLocale = () => {
  return 'en';
}

export const GetUrlParams = () => {
  let Url = require('url-parse');
  let currentURL = new Url(window.location.href);
  let currentQuery = currentURL.query as string;
  currentQuery = currentQuery.substr(1); //Pour retirer le premier "?"

  let queryParts = currentQuery.split('&');

  if (queryParts.length > 0) {
    let currentParams: any = [];
    for (let index = 0; index < queryParts.length; index++) {
      const param = queryParts[index].split('=');

      if (param.length > 1) {
        currentParams[param[0]] = decodeURI(param[1]);
      }
    }

    return currentParams;
  }
  return null;
}

export const isResponsive = window.innerWidth <= 1024;
export const isScreen1200 = window.innerWidth <= 1200;
export const pathname = window.location.pathname;

export const customSelectStyles = {
  option: (provided: any, state: { isSelected: any; isFocused: any }) => ({
    ...provided,
    color: '#51585e',
    backgroundColor: state.isSelected ? "#f9f9f9" : "#fff",
    "&:hover": {
      // Overwrittes the different states of border
      backgroundColor: "#f9f9f9"
    }
  }),
  menu: (provided: any, state: { isSelected: any; isFocused: any }) => ({
    ...provided,
    color: '#51585e',
    backgroundColor: "#f9f9f9",
  }),
}

export interface SelectInterface {
  label: string;
  value: any;
  disabled?: boolean;
}

export const genders: SelectInterface[] = [
  { label: 'Female', value: "0" },
  { label: 'Male', value: "1" },
  { label: 'Prefer not to say', value: "2" }
];

export const relationship: SelectInterface[] = [
  { label: 'Brother', value: SiblingRelationshipType.Brother },
  { label: 'Sister', value: SiblingRelationshipType.Sister },
  { label: 'Half brother', value: SiblingRelationshipType.Half_Brother },
  { label: 'Half sister', value: SiblingRelationshipType.Half_Sister },
  { label: 'Step brother', value: SiblingRelationshipType.Step_Brother },
  { label: 'Step sister', value: SiblingRelationshipType.Step_Sister },
];

export const educationLevel: SelectInterface[] = [
  { label: 'None', value: SiblingEducationLevelType.None },
  { label: 'Nursery', value: SiblingEducationLevelType.Nursery },
  { label: 'Kindergarden', value: SiblingEducationLevelType.Kindergarden },
  { label: 'Primary school', value: SiblingEducationLevelType.Primary_School },
  { label: 'Secondary school', value: SiblingEducationLevelType.Secondary_School },
  { label: 'University / college', value: SiblingEducationLevelType.University_College },
];

export const applicationFormStatusByUser = (isStaff: boolean | undefined, programType: number, isSendingPartner: boolean | undefined, isWepAdmin: boolean) => {
  const applicationFormStatusSelect: SelectInterface[] = [
    { label: 'Empty', value: ApplicationFormSectionStatusEnum.Empty },
  ]

  if(programType === ProgramTypeEnum.Outbound){
    applicationFormStatusSelect.push(
        { label: t('incomplete_by') + ' ' + t('sending_organisation'), value: ApplicationFormSectionStatusEnum.Incomplete },
        { label: t('completed_by') + ' pax', value: ApplicationFormSectionStatusEnum.Completed },
        { label: t('approved_by') + ' ' + t('sending_organisation'), value: ApplicationFormSectionStatusEnum.Approved },
        isWepAdmin ? 
        { label:  t('submitted_by') + ' pax', value: ApplicationFormSectionStatusEnum.Submitted }
      :
        { label: t('in_progress_by') + ' pax', value: ApplicationFormSectionStatusEnum.InProgress }
      );
  }

  if(programType === ProgramTypeEnum.Inbound){
    if(isStaff){
      applicationFormStatusSelect.push(
        { label: t('incomplete_by') + ' ' + t('receiving_organization'), value: ApplicationFormSectionStatusEnum.Incomplete },
        { label: t('approved_by') + ' ' + t('receiving_organization'), value: ApplicationFormSectionStatusEnum.Approved },
        { label: t('completed_by') + ' ' + t('sending_organisation'), value: ApplicationFormSectionStatusEnum.Completed },
        isWepAdmin ? 
          { label: t('submitted_by') + ' ' + t('sending_organisation'), value: ApplicationFormSectionStatusEnum.Submitted }
        :
          { label: t('in_progress_by') + ' ' + t('sending_organisation'), value: ApplicationFormSectionStatusEnum.InProgress }
      );
    }

    if(isSendingPartner && !isStaff){
      applicationFormStatusSelect.push(
        { label: t('incomplete_by') + ' ' + t('sending_organisation'), value: ApplicationFormSectionStatusEnum.Incomplete },
        { label: t('approved_by') + ' ' + t('sending_organisation'), value: ApplicationFormSectionStatusEnum.Approved },
        { label: t('completed_by') + ' pax', value: ApplicationFormSectionStatusEnum.Completed },
        isWepAdmin ? 
          { label: t('submitted_by') + ' pax', value: ApplicationFormSectionStatusEnum.Submitted }
        :
          { label: t('in_progress_by') + ' pax', value: ApplicationFormSectionStatusEnum.InProgress }
      );
    }
  }

  return applicationFormStatusSelect
}

export function redirectToLogin(redirectPath?: string) {
  const redirectParam = redirectPath ? `?redirect=${encodeURIComponent(redirectPath)}` : "";
  window.open(t("url_login") + redirectParam, "_self");
}

export function redirectTo(url: string, addCurrentParams = true, delay?: number) {
  setTimeout(() => window.open(url + (addCurrentParams ? window.location.search : ""), "_self"), delay ?? 0);
}

export const pathnamesWithNoHeaders = () => {
  return [t("url_login"), t("url_password_forgotten"), t("url_password_reset"), t("url_activate_account")];
}

export function storeToast(toastType: ToastType, toastTransCode: string) {
  const itemKey = `Toast_${toastType.toString()}`;

  let existingToasts = sessionStorage.getItem(itemKey);

  if (existingToasts && !existingToasts.includes(toastTransCode)) {
    existingToasts += `,${toastTransCode}`;
  }
  else {
    existingToasts = toastTransCode;
  }

  sessionStorage.setItem(itemKey, existingToasts);
}

export const DateDayWithLeadingZero = (baseDate: Date): string => {
  baseDate = new Date(baseDate);
  const day = baseDate.getDate();
  return day < 10 ? ("0" + day.toString()) : day.toString();
}

export const DateMonthWithLeadingZero = (baseDate: Date): string => {
  baseDate = new Date(baseDate);
  const month = baseDate.getMonth();
  return month < 9 ? ("0" + (month + 1).toString()) : (month + 1).toString();
}

export const DateHoursWithLeadingZero = (baseDate: Date): string => {
  baseDate = new Date(baseDate);
  const hours = baseDate.getHours();
  return hours < 9 ? ("0" + hours.toString()) : hours.toString();
}

export const DateMinutesWithLeadingZero = (baseDate: Date): string => {
  baseDate = new Date(baseDate);
  const minutes = baseDate.getMinutes();
  return minutes < 9 ? ("0" + minutes.toString()) : minutes.toString();
}

export const FormatDateForDisplay = (baseDate: Date, withTime?: boolean): string => {
  baseDate = new Date(baseDate);
  return `${DateDayWithLeadingZero(baseDate)}/${DateMonthWithLeadingZero(baseDate)}/${baseDate.getFullYear()}` + (withTime ? ` ${DateHoursWithLeadingZero(baseDate)}:${DateMinutesWithLeadingZero(baseDate)}` : "");
}

export const FormatDateForUrl = (baseDate: Date): string => {
  let date = new Date(baseDate);
  return date.getFullYear() + "-" + (DateMonthWithLeadingZero(date)) + "-" + DateDayWithLeadingZero(date);
}

export function consumeToasts() {
  const toastOptions = [
    { key: `Toast_${ToastType.SUCCESS.toString()}`, toastFunction: toast.success },
    { key: `Toast_${ToastType.WARNING.toString()}`, toastFunction: toast.warning },
    { key: `Toast_${ToastType.ERROR.toString()}`, toastFunction: toast.error },
  ];

  toastOptions.forEach(toastOption => {
    const storageItems = sessionStorage.getItem(toastOption.key);

    if (storageItems) {
      storageItems.split(",").forEach(successMessage => {
        toastOption.toastFunction(t(successMessage));
      });

      sessionStorage.removeItem(toastOption.key);
    }
  });
}

export const getSelectFromValue = (baseValue: string|number, selects: SelectInterface[]) => {
  const selectValue = selects.find(select => { return select.value.toString() === baseValue.toString() });

  return selectValue !== undefined ? selectValue : null;
};

export function getValueText(answerTemplate: AnswerTemplate, survey: Survey): string {
  var valueText = "";
  if (!survey) {
    return valueText;
  }
  survey.answers.forEach((answer) => {
    if ((answer.answerTemplateId == answerTemplate.id)) {
      valueText = answer.valueText;
      return;
    }
  });
  return valueText;
}

export function getAnswer(answerTemplate: AnswerTemplate, survey: Survey): Answer | null {
  var returnAnswer = null;
  if (!survey) {
    return returnAnswer;
  }
  survey.answers.forEach((answer) => {
    if (answer.answerTemplateId == answerTemplate.id) {
      returnAnswer = answer;
      return;
    }
  });
  return returnAnswer;
}

export function getSurveyWidgetDisplayMode(surveyActions: any, survey: Survey, isSurveyFormReadOnly: boolean): SurveyWidgetDisplayMode {
  let mode = SurveyWidgetDisplayMode.ReadWrite;

  if (isSurveyFormReadOnly) {
    return SurveyWidgetDisplayMode.ReadOnly;
  }

  if (surveyActions !== null) {
    Object.keys(surveyActions).map((index) => {
      if (surveyActions[index] !== null) {

        if (surveyActions[index]?.surveyTemplateId === survey.surveyTemplate.id) {
          if (surveyActions[index]?.surveyActionType === SurveyActionTypeEnum.DisplaySurvey) {
            return mode = 0
          } else if (surveyActions[index]?.surveyActionType === SurveyActionTypeEnum.DisplaySurveyReadOnly) {
            return mode = 1
          }
        }
      }
    })
  }
  return mode
}

export const getFileNameFromContentDisposition = (contentDisposition: string) => {
  const startString = "filename=";
  if(contentDisposition){
    const filenameBeginIndex = contentDisposition.indexOf(startString) + startString.length;
    if(filenameBeginIndex !== -1){
    const filename: string = contentDisposition.substring(filenameBeginIndex, contentDisposition.indexOf(";", filenameBeginIndex));
    return filename.trim();
    }
  }
  return null;
}

export function addErrorToaster(errorMessage: string) {
  toast.error(errorMessage);
}

export const isItalyCulture = (applicationForm: ApplicationForm) => {
  return applicationForm.cultureCode ? (["it", "itit", "it-it", "it_it"]).includes(applicationForm.cultureCode.toLowerCase()) : false;
}

export function toastAxiosError(baseError: AxiosError): void {
  const errorMessage: string | null = baseError.response?.data as string ?? null;

  if (errorMessage) {
    toast.error(errorMessage);
  }
}

export function storeScrollPosition(elementId: string): void
{
  sessionStorage.setItem("scrollToDivId", elementId);
}

export function scrollBackToPosition(): void
{
  const scrollDivId = sessionStorage.getItem("scrollToDivId");
  if(scrollDivId)
  {
    const scrollBackEvent = new CustomEvent("scrollBackToDiv", {
      detail: {"divId": scrollDivId}
    });
  
    document.dispatchEvent(scrollBackEvent);
    sessionStorage.removeItem("scrollToDivId");
  }
}

export function tryScrollBackToPosition(requestings: boolean[]): void
{
  try
  {
    if(sessionStorage.getItem("scrollToDivId") !== null)
    {
      const hasNoOngoingRequesting = requestings.every(req => req === false);

      if(hasNoOngoingRequesting)
      {
        scrollBackToPosition();
      }
    }
  }
  catch(err){}
}

export function updateUrlParams(currentPage: number, props: any)
{
  let params = "";
  params += currentPage ? ("page=" + currentPage) : "";

  props.history.push({search: params});
}

export function getHostnameType(): string
{
  const defaultReturn = "mywep";

  try
  {
    const lookupTable: {hostPart: string, returnValue: string}[] = [
      {hostPart: FRONT_URL as string, returnValue: defaultReturn},
      {hostPart: CLS_URL as string, returnValue: "cls"},
    ];
  
    const hostname = window.location.origin;
  
    const hostnameType = lookupTable.find(tableItem => hostname === tableItem.hostPart);
  
    return hostnameType ? hostnameType.returnValue : defaultReturn;
  }
  catch(err)
  {
    return defaultReturn;
  }
}

export function getHostnameLogo(isDark: boolean = false): string
{
  const defaultReturn = isDark ? "../assets/images/logo/dark-logo.png" : "../assets/images/logo/logo.png";

  try
  {
    const lookupTable: { [x: string]: string } = {
      "mywep": defaultReturn,
      "cls": isDark ? "../assets/images/cls/logo-cls-white.png" : "../assets/images/cls/logo-cls.png"
    };
  
    return lookupTable[getHostnameType()] ?? defaultReturn;
  }
  catch(err)
  {
    return defaultReturn;
  }
}

export function updateTitle(): void
{
  try
  {
    const lookupTable: { [x: string]: string } = {
      "cls": "My CLS",
    };

    const newTitle = lookupTable[getHostnameType()];
  
    if(newTitle !== null && newTitle !== undefined)
    {
      document.title = newTitle;
    }
    
  }
  catch(err){}
}

export function updateFavicon(): void
{
  try
  {
    const lookupTable: { [x: string]: string } = {
      "cls": `${CLS_URL}/assets/images/cls/favicon.ico`,
    };

    const faviconUrl = lookupTable[getHostnameType()];
  
    if(faviconUrl !== null && faviconUrl !== undefined)
    {
      let link: any = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = faviconUrl;
    }
  }
  catch(err){}
}

export function translateAppFormSubLabel(applicationForm: ApplicationForm, isStaff: boolean, isSendingPartner: boolean): string
{
  try
  {
    const selectValues = applicationFormStatusByUser(isStaff, applicationForm.programType, isSendingPartner, true);

    const baseStringTrimmed = applicationForm.subLabel?.trim() ?? "";

    const firstPart = baseStringTrimmed.substring(0, baseStringTrimmed.lastIndexOf(" ")+1);

    const translatedPart = selectValues.find(val => val.value === applicationForm.applicationFormStatus)?.label ?? "";

    return firstPart + translatedPart;
  }
  catch(err)
  {
    return "";
  }
}

export function GetBaseAPIUrl(): string|undefined
{
  const defaultReturn = BASE_API_URL;

  try
  {
    const lookupTable: {hostPart: string, returnValue: string|undefined}[] = [
      {hostPart: FRONT_URL as string, returnValue: BASE_API_URL},
      {hostPart: CLS_URL as string, returnValue: CLS_BASE_API_URL},
    ];
  
    const hostname = window.location.origin;
  
    const apiUrl = lookupTable.find(tableItem => hostname === tableItem.hostPart);
  
    return apiUrl ? apiUrl.returnValue : defaultReturn;
  }
  catch(err)
  {
    return defaultReturn;
  }
}