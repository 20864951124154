import { Modal } from "@mui/material";
import { store } from "app/store";
import { ApplicationForm } from "features/Entity/ApplicationForm";
import { ApplicationFormSection } from "features/Entity/ApplicationFormSection";
import { ApplicationFormSectionStatusEnum } from "features/Entity/SectionStaffInfo";
import { UserData } from "features/Entity/UserData";
import { getCurrentAppForm } from "features/Slice/ApplicationFormSlice";
import React from "react";
import t, { changeLanguage } from "./I18N/TranslateHelpers";
import { SpinnerOnTop } from "./SpinnerOnTop";
import { GetCurrentLocale } from "./Utils";
import { submitApplicationForm } from "./UtilsForApplicationForm";

const locale = GetCurrentLocale();
changeLanguage(locale);


export const createAppFormButton = () => {
	return(
		<a href={t('url_create_application_form')} className="pull-right btn btn-primary small d-flex align-items-center">
			<i className="icon-plus mr-2"></i> {t('create_new_application_form')}
		</a>
	)
}

export const showTextCommentPartner = (isSendingPartner: boolean) => {
	return(
		isSendingPartner ?
			<small>{t('info_to_participant')}</small>
		: null
	)
}

export const badgeApprovedByWep = (isSendingPartner: boolean, appForm: ApplicationForm) => {
	return(
		isSendingPartner && appForm.statusOfValidator === ApplicationFormSectionStatusEnum.Approved ?
			<span className="badge rounded-pill badge-approved ml-2">{ t('approved_by_wep')}</span>
		: null
	)
}

export const getIncompleteSteps = (sectionWithoutStep: ApplicationFormSection[], userInfo: UserData, applicationForm: ApplicationForm) =>  {
	const incompleteSteps = sectionWithoutStep.filter(
		item =>
		  !userInfo?.isSendingPartner ?
			item.validationStatus !== ApplicationFormSectionStatusEnum.Completed &&
			item.validationStatus !== ApplicationFormSectionStatusEnum.Approved
		  :
			item.validationStatus !== ApplicationFormSectionStatusEnum.Approved
		);
	return incompleteSteps
}

export const submitAppFormModal = (modalOpen: boolean, setModalOpen: React.Dispatch<React.SetStateAction<boolean>>, applicationForm: ApplicationForm, isPostingApplicationForm: boolean, userInfo: UserData) => (
	<Modal open={modalOpen}>
	  <div className='card modal-dialog'>
		<div className="modal-header">
		  <h5 className="modal-title" id="confirmModalTitle">{t('confirm_application_modal_title')}</h5>
		  <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setModalOpen(false)}>
			<span aria-hidden="true">&times;</span>
		  </button>
		</div>
		<div className='modal-body'>
		  <p>{t("confirm_application_modal_text")}</p>
		  <div className="d-flex">
			<button className='btn btn-primary mr-3' type='button'
			  onClick={() => setModalOpen(false)}
			>
			  {t('keep_filling_application_form')}
			</button>
			<button className='btn btn-primary-outline' type='button' disabled={isPostingApplicationForm}
			  onClick={() => submitApplicationForm(applicationForm)}
			>
			  { userInfo?.isSendingPartner ?  t('submit_application_form_to_wep') : t('submit_application_form')}
			</button>
		  </div>
		  {
			isPostingApplicationForm ?
			  <SpinnerOnTop />
			  : ""
		  }
		</div>
	  </div>
	</Modal>
  );

export const buttonSubmitAppForm = (userInfo: UserData, setModalOpen: React.Dispatch<React.SetStateAction<boolean>>) => {
	return(
		<button className='btn btn-primary ml-3'type='button' onClick={() => setModalOpen(true)}>
			{ userInfo?.isSendingPartner ?  t('submit_application_form_to_wep') : t('submit_application_form')}
		</button>
	)
}

export const closeModalAndReloadAppForm = (modalOpen: boolean, setModalOpen: React.Dispatch<React.SetStateAction<boolean>>, applicationForm: ApplicationForm) => {
	if(modalOpen) {
        setModalOpen(false);
    }
    
    store.dispatch(getCurrentAppForm(applicationForm.uid, true));
}