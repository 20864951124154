import React, { useRef, useState } from "react";
import t from "../../components/I18N/TranslateHelpers";
import {
	addFileUploadsToFormData,
	getApplicationFormSectionUrl,
	RenderApplicationFormDetailPart,
} from "../../components/UtilsForApplicationForm";
import { ApplicationFormSection } from "../Entity/ApplicationFormSection";
import { RootState, store } from "../../app/store";
import {
	getApplicationFormSectionParts,
	postApplicationFormSection,
} from "../Slice/ApplicationFormSlice";
import { useSelector } from "react-redux";
import { SpinnerOnTop } from "../../components/SpinnerOnTop";
import { useForm } from "react-hook-form";
import { getApplicantFormDto } from "features/Slice/ApplicantSlice";
import { ApplicationFormSectionPart } from "features/Entity/ApplicationFormSectionPart";
import SubmitButton from "components/Forms/SubmitButton";
import { ActionButton } from "components/Print/ActionButton";
import { redirectTo, tryScrollBackToPosition } from "components/Utils";
import { ApplicationForm } from "features/Entity/ApplicationForm";
import ApplicationFormFinalStep from "./Sections/ApplicationFormFinalStep";
import ApplicationFormDefine from "./Sections/ApplicationFormDefine";
import UploadPDFSection from "components/Forms/UploadPDFSection";

interface ComponentProps {
	applicationForm: ApplicationForm;
	applicationFormSection: ApplicationFormSection;
	nextSection: ApplicationFormSection | null;
	isReadOnly: boolean;
}

const ApplicationFormSectionDetails: React.FC<ComponentProps> = ({
	applicationForm,
	applicationFormSection,
	nextSection,
	isReadOnly
}: ComponentProps) => {
	const isRequestingSteps = useSelector((state: RootState) => state.applicationFormRdc.isRequestingParts);
	const applicationFormSectionParts = useSelector((state: RootState) => state.applicationFormRdc.applicationFormSectionParts);
	const isRequestingApplicantData = useSelector((state: RootState) => state.applicantRdc.isRequesting);
	const applicantData = useSelector((state: RootState) => state.applicantRdc.applicantFormDto);
	const isPostingForm = useSelector((state: RootState) => state.applicationFormRdc.isRequestPost);
	const isFormPosted = useSelector((state: RootState) => state.applicationFormRdc.isRequestPostSuccess);

	const isRequestingAppForms = useSelector((state: RootState) => state.applicationFormRdc.isRequesting);
	const isRequestingAgreements = useSelector((state: RootState) => state.agreementRdc.isRequestingList);
	const isRequestingLetters = useSelector((state: RootState) => state.letterRdc.isRequesting);
	const isRequestingMediaAlbum = useSelector((state: RootState) => state.mediaAlbumRdc.isRequesting);
	const isRequestingPhysician = useSelector((state: RootState) => state.physicianRdc.isRequesting);
	const isRequestingSchools = useSelector((state: RootState) => state.schoolRdc.isRequesting);
	const isRequestingSurveys = useSelector((state: RootState) => state.surveyRdc.isRequesting);
	const isRequestingTeacher = useSelector((state: RootState) => state.teacherRdc.isRequesting);
	const isRequestingTutorials = useSelector((state: RootState) => state.tutorialRdc.isRequesting);

	const [goToNextSection, setGoToNextSection] = useState<boolean>(false);

	const isLastSection = getApplicationFormSectionUrl(applicationFormSection).startsWith(t("url_application_finish"));
	const isDefineSection = getApplicationFormSectionUrl(applicationFormSection).startsWith(t("url_application_form_define"));
	const isAgreementSection = getApplicationFormSectionUrl(applicationFormSection).startsWith(t("url_application_agreement"));

	const isFirstOrLastSection = isLastSection || isDefineSection;

	React.useEffect(() => {
		if (!isFirstOrLastSection) {
			store.dispatch(
				getApplicationFormSectionParts(
					applicationForm.uid,
					applicationFormSection.id,
				),
			);
		}

		store.dispatch(
			getApplicantFormDto(applicationForm.uid, applicationFormSection.id),
		);
	}, []);

	React.useEffect(() => {
		if (nextSection && isFormPosted && goToNextSection) {
			redirectTo(getApplicationFormSectionUrl(nextSection), false, 500);
		}
		else if (isFormPosted && !goToNextSection && nextSection) {
			window.location.reload();
		}
	}, [isFormPosted]);

	React.useEffect(() => {
		tryScrollBackToPosition([isRequestingSteps, isRequestingApplicantData, isRequestingAppForms, isRequestingAgreements, isRequestingLetters, isRequestingMediaAlbum, isRequestingPhysician, isRequestingSchools, isRequestingSurveys, isRequestingTeacher, isRequestingTutorials, isFormPosted, isPostingForm]);
	}, [isRequestingSteps, isRequestingApplicantData, isRequestingAppForms, isRequestingAgreements, isRequestingLetters, isRequestingMediaAlbum, isRequestingPhysician, isRequestingSchools, isRequestingSurveys, isRequestingTeacher, isRequestingTutorials]);

	const {
		register,
		handleSubmit,
		watch,
		unregister,
		control,
		formState: { errors },
		setValue,
		getValues,
	} = useForm({ mode: "onChange" });


	async function onSubmit(formData: any) {
		await addFileUploadsToFormData(formData);
		store.dispatch(
			postApplicationFormSection(
				applicationForm.uid,
				applicationFormSection.id,
				formData,
			),
		);
	}

	const submitButtonRow = () => {
		return (
			<div className="form-group d-flex justify-content-end buttons-submit">
				<div className="mr-3">
					<SubmitButton
						label={t("update")}
						isPostingForm={isPostingForm}
					/>
				</div>
				{nextSection ? (
					<SubmitButton
						label={t("update_my_data_and_next")}
						isPostingForm={isPostingForm}
						extraActionOnClick={() => setGoToNextSection(true)}
					/>
				) : (
					""
				)}
			</div>
		);
	};

	return isRequestingSteps || isRequestingApplicantData || !applicantData ? (
		<SpinnerOnTop />
	) : (
		<>
			<div className="default-according student-accordion" id="accordionap">
				<div className="col-xl-12 xl-80 col-lg-12 col-md-12 box-col-12">
					<form onSubmit={handleSubmit(onSubmit)}>
						{!isFirstOrLastSection && !isAgreementSection ? (
							<div className="col-md-12 footer-button-container">
								<div className="pb-3 d-flex justify-content-end pdf-buttons">
									<ActionButton
										applicationForm={applicationForm}
										section={applicationFormSection}
									/>
									{!isReadOnly ? submitButtonRow() : ""}
								</div>
							</div>
						) : (!isFirstOrLastSection && !isReadOnly && !isAgreementSection) ?
							submitButtonRow()
							: ""
						}
						{
							!isFirstOrLastSection ?
								<div>
									{applicationFormSectionParts?.map(
										(part: ApplicationFormSectionPart, index: number) => {
											return (
												<React.Fragment key={index}>
													{RenderApplicationFormDetailPart(
														applicationForm,
														applicationFormSection,
														part,
														register,
														watch,
														unregister,
														errors,
														control,
														applicantData,
														setValue,
														getValues,
														onSubmit,
														isReadOnly,
														isPostingForm
													)}
												</React.Fragment>
											);
										},
									)}
									{applicationFormSection.canReuploadSectionPDF ? <UploadPDFSection isReadOnly={isReadOnly} applicationFormUid={applicationForm.uid} section={applicationFormSection} register={register} /> : ""}
									{!isReadOnly && !isAgreementSection ? submitButtonRow() : ""}
								</div>
								:
								isDefineSection ?
									<ApplicationFormDefine applicationForm={applicationForm} section={applicationFormSection} />
									:
									<ApplicationFormFinalStep applicationForm={applicationForm} section={applicationFormSection} />
						}
					</form>
				</div>
			</div>
		</>
	);
};

export default ApplicationFormSectionDetails;
