import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { toastAxiosError } from "components/Utils";
import { downloadAgreementsConcatPDFRep, downloadAgreementsPDFRep, downloadAllFilesRep, DownloadPhotoAlbumRep, downloadSectionsPDFRep, downloadSeparateTemplatesRep, DownloadVirtualFolderRep, exportDataToOMGTRep, generateTemporaryLinkWithAllFilesRep, synchronizeApplicationFormStatusRep, downloadSectionsConcatPDFRep, notifyAppFormUserOfAccountRep } from "features/Repository/StaffDefineRepository";import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import { AppThunk } from "../../app/store";
import t from "../../components/I18N/TranslateHelpers";

interface StaffDefineState {
  error: AxiosError | null;
  isSyncing: boolean;
  ongoingActions: string[];
}

const initialState: StaffDefineState = {
  error: null,
  isSyncing: false,
  ongoingActions: []
}

function startSyncingRdc(state: StaffDefineState) {
  state.isSyncing = true;
}

function stopSyncingRdc(state: StaffDefineState) {
  state.isSyncing = false;
}

function startRequestRdc(state: StaffDefineState, action: PayloadAction<string>) {
  state.error = null;
  if (!state.ongoingActions.includes(action.payload)) {
    state.ongoingActions.push(action.payload);
  }
}

function requestFailedRdc(state: StaffDefineState, action: PayloadAction<{actionName: string, error: AxiosError}>) {
  state.error = action.payload.error;
  if (state.ongoingActions.includes(action.payload.actionName)) {
    state.ongoingActions = state.ongoingActions.filter(act => act !== action.payload.actionName);
  }
  toast.error(t("error_downloading_file"));
}

function requestSuccessRdc(state: StaffDefineState, action: PayloadAction<string>) {
  state.error = null;
  if (state.ongoingActions.includes(action.payload)) {
    state.ongoingActions = state.ongoingActions.filter(act => act !== action.payload);
  }
}

const StaffDefineSlice = createSlice(
  {
    name: 'StaffDefine',
    initialState: initialState,
    reducers: {
      startRequestAct: startRequestRdc,
      requestSuccessAct: requestSuccessRdc,
      requestFailedAct: requestFailedRdc,
      startSyncingcAct: startSyncingRdc,
      stopSyncingcAct: stopSyncingRdc
    }
  }
)

export const {
  startRequestAct,
  requestSuccessAct,
  requestFailedAct,
  startSyncingcAct,
  stopSyncingcAct
} = StaffDefineSlice.actions;

export default StaffDefineSlice.reducer;

export const downloadSectionsPDF = (applicationFormUid: string, actionName: string): AppThunk => async dispatch => {
  try {
    dispatch(startRequestAct(actionName));
    const apiResponse = await downloadSectionsPDFRep(applicationFormUid);
    saveAs(apiResponse.blob, apiResponse.filename);
    dispatch(requestSuccessAct(actionName));
  } catch (err) {
    toastAxiosError(err as AxiosError);
    dispatch(requestFailedAct({actionName, error: err as AxiosError}));
  }
}

export const downloadAgreementsPDF = (applicationFormUid: string, actionName: string): AppThunk => async dispatch => {
  try {
    dispatch(startRequestAct(actionName));
    const apiResponse = await downloadAgreementsPDFRep(applicationFormUid);
    saveAs(apiResponse.blob, apiResponse.filename);
    dispatch(requestSuccessAct(actionName));
  } catch (err) {
    toastAxiosError(err as AxiosError);
    dispatch(requestFailedAct({actionName, error: err as AxiosError}));
  }
}

export const downloadAllFiles = (applicationFormUid: string, filename: string, actionName: string): AppThunk => async dispatch => {
  try {
    dispatch(startRequestAct(actionName));
    const apiResponse = await downloadAllFilesRep(applicationFormUid);
    saveAs(apiResponse, filename);
    dispatch(requestSuccessAct(actionName));
  } catch (err) {
    toastAxiosError(err as AxiosError);
    dispatch(requestFailedAct({actionName, error: err as AxiosError}));
  }
}

export const generateTemporaryLinkWithAllFiles = (applicationFormUid: string, filename: string, actionName: string): AppThunk => async dispatch => {
  try {
    dispatch(startRequestAct(actionName));
    const apiResponse = await generateTemporaryLinkWithAllFilesRep(applicationFormUid);
    saveAs(apiResponse, filename);
    dispatch(requestSuccessAct(actionName));
  } catch (err) {
    toastAxiosError(err as AxiosError);
    dispatch(requestFailedAct({actionName, error: err as AxiosError}));
  }
}

export const downloadSeparateTemplates = (applicationFormUid: string, filename: string, actionName: string): AppThunk => async dispatch => {
  try {
    dispatch(startRequestAct(actionName));
    const apiResponse = await downloadSeparateTemplatesRep(applicationFormUid);
    saveAs(apiResponse, filename);
    dispatch(requestSuccessAct(actionName));
  } catch (err) {
    toastAxiosError(err as AxiosError);
    dispatch(requestFailedAct({actionName, error: err as AxiosError}));
  }
}

export const synchronizeApplicationFormStatus = (applicationFormUid: string, applicationFormStatus: number): AppThunk => async dispatch => {
  try {
    dispatch(startSyncingcAct());
    await synchronizeApplicationFormStatusRep({ appFormUid: applicationFormUid, applicationFormStatus: applicationFormStatus });
    dispatch(stopSyncingcAct());
    toast.success(t("sync_omgt_success"));
  } catch (err) {
    dispatch(stopSyncingcAct());
    toastAxiosError(err as AxiosError);
  }
}

export const exportDataToOMGT = (applicationFormUid: string): AppThunk => async dispatch => {
  try {
    dispatch(startSyncingcAct());
    await exportDataToOMGTRep(applicationFormUid);
    dispatch(stopSyncingcAct());
    toast.success(t("export_omgt_success"));
  } catch (err) {
    dispatch(stopSyncingcAct());
    toastAxiosError(err as AxiosError);
  }
}


export const downloadVirtualFolder = (applicationFormUid: string, actionName: string): AppThunk => async dispatch => {
  try {
    dispatch(startRequestAct(actionName));
    const apiResponse = await DownloadVirtualFolderRep(applicationFormUid);

    saveAs(apiResponse.blob, apiResponse.filename);
    dispatch(requestSuccessAct(actionName));
  } catch (err) {
    toastAxiosError(err as AxiosError);
    dispatch(requestFailedAct({actionName, error: err as AxiosError}));
  }
}

export const downloadPhotoAlbum = (applicationFormUid: string, actionName: string): AppThunk => async dispatch => {
  try {
    dispatch(startRequestAct(actionName));
    const apiResponse = await DownloadPhotoAlbumRep(applicationFormUid);

    saveAs(apiResponse.blob, apiResponse.filename);
    dispatch(requestSuccessAct(actionName));
  } catch (err) {
    toastAxiosError(err as AxiosError);
    dispatch(requestFailedAct({actionName, error: err as AxiosError}));
  }
}

export const notifyAppFormUserOfAccount = (applicationFormUid: string): AppThunk => async dispatch => {
  try {
    dispatch(startSyncingcAct());
    await notifyAppFormUserOfAccountRep(applicationFormUid);
    dispatch(stopSyncingcAct());
    toast.success(t("email_sent"));
  } catch (err) {
    dispatch(stopSyncingcAct());
    toastAxiosError(err as AxiosError);
  }
}

export const downloadSectionsConcatPDF = (applicationFormUid: string, actionName: string): AppThunk => async dispatch => {
  try {
    dispatch(startRequestAct(actionName));
    const apiResponse = await downloadSectionsConcatPDFRep(applicationFormUid);
    saveAs(apiResponse.blob, apiResponse.filename);
    dispatch(requestSuccessAct(actionName));
  } catch (err) {
    toastAxiosError(err as AxiosError);
    dispatch(requestFailedAct({actionName, error: err as AxiosError}));
  }
}

export const downloadAgreementsConcatPDF = (applicationFormUid: string, actionName: string): AppThunk => async dispatch => {
  try {
    dispatch(startRequestAct(actionName));
    const apiResponse = await downloadAgreementsConcatPDFRep(applicationFormUid);
    saveAs(apiResponse.blob, apiResponse.filename);
    dispatch(requestSuccessAct(actionName));
  } catch (err) {
    toastAxiosError(err as AxiosError);
    dispatch(requestFailedAct({actionName, error: err as AxiosError}));
  }
}
