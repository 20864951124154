export interface SectionStaffInfo
{
    appFormSectionId: number;
    validationStatus: ApplicationFormSectionStatusEnum;
    validationComment: string|null;
}

export enum ApplicationFormSectionStatusEnum
{
    Empty = 0,
    InProgress = 1,
    Completed = 2,
    Submitted = 3,
    Incomplete = 4,
    Approved = 5,
}