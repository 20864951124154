import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { WEPProducts } from "features/Entity/WEPProducts";
import { getWEPProductsRep, getWEPProductYearsRep } from "features/Repository/CreateApplicationFormRepository";
import { AppThunk } from "../../app/store";

interface CreateApplicationFormState {
  isRequesting: boolean,
  isRequestSuccess: boolean,
  isRequestFailed: boolean,
  isRequestingWEPProducts: boolean,
  isRequestWEPProductsSuccess: boolean,
  isRequestWEPProductsFailed: boolean,
  error: AxiosError | null,
  productYears: string[]|null,
  WEPProducts: WEPProducts[]|null
}

const initialState: CreateApplicationFormState = {
  isRequesting: false,
  isRequestSuccess: false,
  isRequestFailed: false,
  error: null,
  productYears: null,
  isRequestingWEPProducts: false,
  isRequestWEPProductsFailed: false,
  isRequestWEPProductsSuccess: false,
  WEPProducts: null
}

function startRequestRdc(state: CreateApplicationFormState) {
  state.isRequesting = true;
  state.isRequestFailed = false;
  state.error = null;
}

function requestFailedRdc(state: CreateApplicationFormState, action: PayloadAction<AxiosError>) {
  state.isRequesting = false;
  state.isRequestSuccess = false;
  state.isRequestFailed = true;
  state.error = action.payload;
}

function requestAllProductYearsSuccessRdc(state: CreateApplicationFormState, action: PayloadAction<string[]>) {
  state.isRequesting = false;
  state.isRequestSuccess = true;
  state.isRequestFailed = false;
  state.error = null;
  state.productYears = action.payload;
}

function startWEPProductsRequestRdc(state: CreateApplicationFormState) {
  state.isRequestingWEPProducts = true;
  state.isRequestWEPProductsFailed = false;
  state.error = null;
}

function requestWEPProductsFailedRdc(state: CreateApplicationFormState, action: PayloadAction<AxiosError>) {
  state.isRequestingWEPProducts = false;
  state.isRequestWEPProductsSuccess = false;
  state.isRequestWEPProductsFailed = true;
  state.error = action.payload;
}

function requestWEPProductSuccessRdc(state: CreateApplicationFormState, action: PayloadAction<WEPProducts[]>) {
  state.isRequestingWEPProducts = false;
  state.isRequestWEPProductsSuccess = true;
  state.isRequestWEPProductsFailed = false;
  state.error = null;
  state.WEPProducts = action.payload;
}

const CreateApplicationFormSlice = createSlice({
    name: 'ProductYears',
    initialState: initialState,
    reducers: {
      startRequestAct: startRequestRdc,
      requestFailedAct: requestFailedRdc,
      requestAllProductYearsSuccessAct: requestAllProductYearsSuccessRdc,
      startWEPProductsRequestAct: startWEPProductsRequestRdc,
      requestWEPProductsFailedAct: requestWEPProductsFailedRdc,
      requestWEPProductSuccessAct: requestWEPProductSuccessRdc,
    }
  }
)

export const {
  startRequestAct,
  requestFailedAct,
  requestAllProductYearsSuccessAct,
  requestWEPProductSuccessAct,
  requestWEPProductsFailedAct,
  startWEPProductsRequestAct
} = CreateApplicationFormSlice.actions;

export default CreateApplicationFormSlice.reducer;

export const getWEPProductYears = (cultureCode: string): AppThunk => async dispatch =>
{
    try
    {
        dispatch(startRequestAct());
        const apiResponse = await getWEPProductYearsRep(cultureCode);
        dispatch(requestAllProductYearsSuccessAct(apiResponse));
    } catch (err)
    {
        dispatch(requestFailedAct(err as AxiosError));
    }
}

export const getWEPProducts = (cultureCode: string, year: string): AppThunk => async dispatch =>
{
    try
    {
        dispatch(startWEPProductsRequestAct());
        const apiResponse = await getWEPProductsRep(cultureCode, year);
        dispatch(requestWEPProductSuccessAct(apiResponse));
    } catch (err)
    {
        dispatch(requestFailedAct(err as AxiosError));
    }
}