import axios  from "components/AxiosUtils";
import { GetFinalStepData } from "../../components/UtilsForApplicationForm";
import { ApplicationFormSection, SectionFileUpload } from "../Entity/ApplicationFormSection";
import { ApplicationForm, ApplicationFormSectionStatus, DashboardSectionTypeEnum } from "../Entity/ApplicationForm";
import { ApplicationFormSectionParts } from "../Entity/ApplicationFormSectionParts";
import { ApplicationFormSectionPart } from "features/Entity/ApplicationFormSectionPart";
import { FileInfo } from "features/Entity/Agreement";
import { SectionStaffInfo } from "features/Entity/SectionStaffInfo";
import { Pager } from "features/Entity/Pager";

export async function getApplicationFormsRep(): Promise<ApplicationForm[]> {
    try {
        const response = await axios.get<ApplicationForm[]>(
            `/ApplicationForm/GetAppFormsOfUser`,
        );

        return response.data;
    }
    catch (err: any) {
        throw err;
    }
}

export async function getApplicationFormsWithPaginationRep(limit: number, page: number): Promise<Pager<ApplicationForm>> {
    try {
        const response = await axios.get<Pager<ApplicationForm>>(
            `/ApplicationForm/AppFormsOfUser?limit=${limit}&page=${page}`,
        );

        return response.data;
    }
    catch (err: any) {
        throw err;
    }
}

export async function getApplicationFormRep(applicationFormUid: string): Promise<ApplicationForm> {
    try {
        const response = await axios.get<ApplicationForm>(
            `/ApplicationForm/GetAppForm?uid=${applicationFormUid}`,
        );

        return response.data;
    }
    catch (err: any) {
        throw err;
    }
}

export async function getCurrentAppFormRep(uid: string|null): Promise<ApplicationForm> {
    try {
        const response = await axios.get<ApplicationForm>(
            `/ApplicationForm/GetCurrentAppForm${uid ? `?uid=${uid}` : ""}`,
        );

        return response.data;
    }
    catch (err: any) {
        throw err;
    }
}

export async function getApplicationFormSectionsRep(applicationForm: ApplicationForm): Promise<ApplicationFormSection[]> {
    try {
        const response = await axios.get<ApplicationFormSection[]>(
            `/ApplicationForm/${applicationForm.uid}/GetSections/true`,
        );

        let applicationFormSections = response.data;

        applicationFormSections.forEach((section, index) => {
            section.orderNumber = index + 1;
        });

        applicationFormSections = applicationFormSections.sort((a, b) => a.orderNumber - b.orderNumber);

        const finalStep = GetFinalStepData(applicationForm, applicationFormSections);

        if (finalStep) {
            applicationFormSections.push(finalStep);
        }

        return applicationFormSections;
    }
    catch (err: any) {
        throw err;
    }
}

export async function getApplicationFormSectionPartsRep(applicationFormUid: string, applicationFormSectionId: number): Promise<ApplicationFormSectionPart[]> {
    try {
        const response = await axios.get<ApplicationFormSectionParts>(
            `/ApplicationFormSection/GetSectionParts?sectionId=${applicationFormSectionId}&uid=${applicationFormUid}`,
        );

        return response.data.parts;
    }
    catch (err: any) {
        throw err;
    }
}

export async function postApplicationFormSectionRep(applicationFormUid: string, sectionId: number, formValues: any): Promise<null> {
    try {
        const response = await axios.post<null>(
            `/ApplicationFormSection/SaveSectionParts?sectionId=${sectionId}&uid=${applicationFormUid}`,
            formValues,
        );

        return response.data;
    }
    catch (err: any) {
        throw err;
    }
}

export async function postApplicationFormRep(applicationFormUid: string): Promise<null> {
    try {
        const response = await axios.post<null>(
            `/ApplicationForm/SubmitApplicationForm/${applicationFormUid}`,
            undefined,
        );

        return response.data;
    }
    catch (err: any) {
        throw err;
    }
}

export async function getApplicationFormSectionStatusRep(applicationFormUid: string): Promise<ApplicationFormSectionStatus> {
    try {
        const response = await axios.get<ApplicationFormSectionStatus>(
            `/ApplicationForm/${applicationFormUid}/ApplicationFormSectionStatus`,
        );

        return response.data;
    }
    catch (err: any) {
        throw err;
    }
}


export async function getApplicationFormSectionStatusOfValidatorRep(applicationFormUid: string): Promise<ApplicationFormSectionStatus> {
    try {
        const response = await axios.get<ApplicationFormSectionStatus>(
            `/ApplicationForm/${applicationFormUid}/ApplicationFormSectionStatusOfValidator`,
        );

        return response.data;
    }
    catch (err: any) {
        throw err;
    }
}

export async function postApplicationFormSectionValidationStatusRep(formData: SectionStaffInfo, applicationFormUid: string): Promise<void> {
    try {
        await axios.post(
            `/ApplicationFormSection/SaveAppFormSectionValidationDTO?uid=${applicationFormUid}`,
            { ...formData },
        );
    }
    catch (err: any) {
        throw err;
    }
}

export async function getAppFormSectionPDFRep(uid: string, sectionId: number, isAnonymous: boolean, isEmpty: boolean,sectionSlug: string): Promise<FileInfo> {
    try {
        const response = await axios.get<ArrayBuffer>(
            `/ApplicationFormSection/${uid}/GetAppFormSectionPDF/sectionId/isAnonymous/isEmpty?sectionId=${sectionId}&isAnonymous=${isAnonymous}&isEmpty=${isEmpty}`,
            { headers: { "Content-Type": "application/octet-stream" }, responseType: 'arraybuffer' }
        );
        // const contentDispo = response.request.getResponseHeader('Content-Disposition');
        // console.log(contentDispo)
        // const filename = getFileNameFromContentDisposition(response.headers["Content-Disposition"]);
        const blob = new Blob([new Uint8Array(response.data)]);
        let filename: string = sectionSlug;
        const fileInfo: FileInfo = { fileBlob: blob, filename: `${filename}.pdf` };
        return fileInfo;
    }
    catch (err: any) {
        throw err;
    }
}

export async function postUpdateApplicationFormStatusRep(formData: {}): Promise<void> {
    try {
        await axios.post(
            `/ApplicationForm/SaveAppFormInfoDTO`,
            { ...formData },
        );
    }
    catch (err: any) {
        throw err;
    }
}

export async function uploadApplicationFormSectionFileRep(data: SectionFileUpload): Promise<void> {
    try {
        await axios.post(
            `/ApplicationFormSection/UploadFile`,
            data,
            { headers: {  "Content-Type": "multipart/form-data" } }
        );
    }
    catch (err: any) {
        throw err;
    }
}

export async function downloadApplicationFormSectionFileRep(applicationFormUid: string, sectionId: number): Promise<Blob> {
    try {
        const response = await axios.get<ArrayBuffer>(
            `/ApplicationFormSection/DownloadFile/${applicationFormUid}?sectionId=${sectionId}`,
            { headers: { "Content-Type": "application/octet-stream" }, responseType: 'arraybuffer' }
        );

        const blob = new Blob([new Uint8Array(response.data)]);

        return blob;
    }
    catch (err: any) {
        throw err;
    }
}

export async function createApplicationFormRep(formValues: {}): Promise<string> {
    try {
        const response = await axios.post<string>(
            `/ApplicationForm/CreatePartnerApplicationForm`,
            formValues,
        );

        return response.data;
    }
    catch (err: any) {
        throw err;
    }
}

export async function getDashboardApplicationFormListRep(year: number, listName: string, departurePeriod: string|null, sectionType: DashboardSectionTypeEnum): Promise<ApplicationForm[]> {
    try {
        const response = await axios.get<ApplicationForm[]>(
            `/ApplicationForm/GetDashboardApplicationFormList?SectionType=${sectionType}&ListName=${listName}&Year=${year}${departurePeriod ? `&DeparturePeriod=${departurePeriod}` : ""}`,
        );

        return response.data;
    }
    catch (err: any) {
        throw err;
    }
}



export async function generatePDFRep(sectionId: number, uid: string, isAnonymous: boolean, isEmpty: boolean): Promise<FileInfo> {
    try {
        const response = await axios.post<ArrayBuffer>(
            `/PDF/GeneratePDFForAppFormSection`,
            {
                sectionId: sectionId,
                uid: uid,
                isAnonymous: isAnonymous,
                isEmpty: isEmpty,
            },
            { headers: { "Content-Type": "application/json" }, responseType: 'arraybuffer' }
        );

        const blob = new Blob([response.data]);

        let filename: string = "testPDF";

        const fileInfo: FileInfo = { fileBlob: blob, filename: `${filename}.pdf` };

        return fileInfo;
    }
    catch (err: any) {
        throw err;
    }
}