import { combineReducers } from '@reduxjs/toolkit';
import userRdc from "../features/Slice/UserSlice";
import tutorialRdc from "../features/Slice/TutorialSlice";
import applicationFormRdc from "../features/Slice/ApplicationFormSlice";
import applicantRdc from "../features/Slice/ApplicantSlice";
import surveyRdc from "../features/Slice/SurveySlice";
import letterRdc from "../features/Slice/LetterSlice";
import mediaAlbumRdc from "../features/Slice/MediaAlbumSlice";
import schoolRdc from "../features/Slice/SchoolSlice";
import teacherRdc from "../features/Slice/TeacherSlice";
import physicianRdc from "../features/Slice/PhysicianSlice";
import agreementRdc from "../features/Slice/AgreementSlice";
import documentRdc from "../features/Slice/DocumentSlice";
import virtualFolderRdc from "../features/Slice/VirtualFolderSlice";
import cultureRdc from "../features/Slice/CultureSlice";
import alertRdc from "../features/Slice/AlertSlice";
import staffDefineRdc from "../features/Slice/StaffDefineSlice";
import applicationFormTemplatesRdc from "../features/Slice/ApplicationFormTemplateSlice"
import applicationFormSelectRdc from "../features/Slice/ApplicationFormSelectSlice"
import countriesRdc from "../features/Slice/CountrySlice"
import nationalitiesRdc from "../features/Slice/NationalitySlice"
import motherTonguesRdc from "../features/Slice/MotherTongueSlice"
import createApplicationFormRdc from "../features/Slice/CreateApplicationFormSlice"

const RootReducer = combineReducers({
  userRdc,
  tutorialRdc,
  applicationFormRdc,
  applicantRdc,
  surveyRdc,
  letterRdc,
  mediaAlbumRdc,
  schoolRdc,
  teacherRdc,
  physicianRdc,
  agreementRdc,
  documentRdc,
  virtualFolderRdc,
  cultureRdc,
  alertRdc,
  staffDefineRdc,
  applicationFormTemplatesRdc,
  applicationFormSelectRdc,
  countriesRdc,
  nationalitiesRdc,
  motherTonguesRdc,
  createApplicationFormRdc,
});

export type RootState = ReturnType<typeof RootReducer>;
export default RootReducer;
