import React from "react";
import {
	Control,
	FieldValues,
	UseFormGetValues,
	UseFormRegister,
	UseFormSetValue,
	UseFormUnregister,
	UseFormWatch,
} from "react-hook-form";
import slugify from "slugify";
import t, { changeLanguage } from "../components/I18N/TranslateHelpers";
import ApplicantEmergencyInfoForm from "../features/ApplicationForm/Sections/ApplicantEmergencyInfoForm";
import ApplicantForm from "../features/ApplicationForm/Sections/ApplicantForm";
import ApplicantLegalGuardianForm from "../features/ApplicationForm/Sections/ApplicantLegalGuardianForm";
import ApplicantHobbiesForm from "../features/ApplicationForm/Sections/ApplicantHobbiesForm";
import ApplicationFormTutorial from "../features/ApplicationForm/Sections/ApplicationFormTutorial";
import { ApplicationForm } from "../features/Entity/ApplicationForm";
import { ApplicationFormSection } from "../features/Entity/ApplicationFormSection";
import AddNewFieldsRow from "./Forms/AddNewFieldsRow";
import RenderCollapse from "./RenderCollapse";
import { GetCurrentLocale, GetUrlParams, pathname, redirectTo, SelectInterface, storeScrollPosition, storeToast, translateAppFormSubLabel } from "./Utils";
import SurveyForm from "features/Survey/SurveyForm";
import { Applicant } from "features/Entity/Applicant";
import PhotoAlbum from "features/ApplicationForm/PhotoAlbum";
import ApplicantSchoolTranscriptsForm from "features/ApplicationForm/Sections/School/ApplicantSchoolTranscriptsForm";
import { ApplicationFormSectionPart } from "features/Entity/ApplicationFormSectionPart";
import ApplicantFormDetails from "features/ApplicationForm/Sections/ApplicantFormDetails";
import ApplicantSchoolInfoForm from "features/ApplicationForm/Sections/School/ApplicantSchoolInfoForm";
import LetterForm from "features/ApplicationForm/Sections/LetterForm";
import ApplicantTeacherRecommendationForm from "features/ApplicationForm/Sections/Teacher/ApplicantTeacherRecommendationForm";
import ApplicantTeacherInfoForm from "features/ApplicationForm/Sections/Teacher/ApplicantTeacherInfoForm";
import ApplicantLanguageTeacherInfoForm from "features/ApplicationForm/Sections/LanguageTeacher/ApplicantLanguageTeacherInfoForm";
import ApplicantLanguageTeacherRecommendationForm from "features/ApplicationForm/Sections/LanguageTeacher/ApplicantLanguageTeacherRecommendationForm";
import ApplicantAgreementForm from "features/ApplicationForm/ApplicantAgreementForm";
import PhysicianInfos from "features/ApplicationForm/PhysicianInfos";
import SectionStaffManagement from "features/ApplicationForm/Sections/SectionStaffManagementPart";
import RenderConditional from "./RenderConditional";
import { ApplicationFormSectionStatusEnum } from "features/Entity/SectionStaffInfo";
import { LetterTypeEnum } from "features/Entity/Letter";
import ApplicantFormValidationInfo from "features/ApplicationForm/Sections/ApplicantFormValidationInfo";
import ApplicantSmokerForm from "features/ApplicationForm/Sections/ApplicantSmokerForm";
import { store } from "app/store";
import { postApplicationForm } from "features/Slice/ApplicationFormSlice";
import { UserData } from "features/Entity/UserData";
import { badgeApprovedByWep } from "./UtilsForPartner";
import ApplicationFormSelect from "features/ApplicationForm/ApplicationFormSelect";

const locale = GetCurrentLocale();
changeLanguage(locale);

export const saveButton = (isBtnOutline: boolean, onClick?: ((formData: any) => Promise<void>) | ((data: any) => void), customLabel?: string, customId?: string, buttonType?: "submit" | "button", disabledButton?: boolean) => {
	return (
		<button
			disabled={disabledButton ?? false}
			id={customId}
			className={
				isBtnOutline
					? "btn btn-primary-outline mr-2 ml-2"
					: "btn btn-primary mr-2 ml-2"
			}
			type={buttonType ?? "submit"}
			onClick={onClick ?? undefined}
		>
			<i className="icofont icofont-save"></i> {customLabel ?? t("save_this_photo")}
		</button>
	);
};

const sectionSaveButton = (sectionPartName?: string, disabledButton?: boolean) =>
(
	<div className="form-group d-flex justify-content-end buttons-submit">
		<div className="mr-3 mt-3">
			{saveButton(false, sectionPartName ? () => storeScrollPosition(`submit-${sectionPartName}`) : undefined, t('update'), sectionPartName ? `submit-${sectionPartName}` : undefined, undefined, disabledButton)}
		</div>
	</div>
)

export async function readFileAsDataURL(file: File) {
	const result_base64 = await new Promise((resolve) => {
		let fileReader = new FileReader();
		fileReader.onload = () => resolve(fileReader.result);
		fileReader.readAsDataURL(file);
	});

	return result_base64;
}

export async function addFileUpload(
	formData: any,
	uploadFieldName: string,
	formElement: any,
	parentElement: any,
) {
	if (formElement instanceof FileList) {
		if (formElement.length > 0) {
			const file: File = formElement[0];

			const datafile = await readFileAsDataURL(file);

			parentElement[
				`${uploadFieldName}Base64`
			] = `filename:${slugify(file.name.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, ''))},${datafile}`;
		} else {
			parentElement[`${uploadFieldName}Base64`] = "";
		}

		if (typeof formElement !== "string") {
			parentElement[`${uploadFieldName}`] = "";
		}
	} else {
		if (Array.isArray(formElement)) {
			for (let index = 0; index < formElement.length; index++) {
				if (
					formElement[index] instanceof FileList ||
					typeof formElement[index] === "object"
				) {
					await addFileUpload(
						formData,
						index.toString(),
						formElement[index],
						formElement,
					);
				}
			}
		} else if (typeof formElement === "object" && formElement !== null) {
			if(formElement instanceof Date) //Converting dates to UTC
			{
				const newDate = new Date(Date.UTC(formElement.getFullYear(), formElement.getMonth(), formElement.getDate()));
				parentElement[`${uploadFieldName}`] = newDate;
			}
			else
			{
				for (const uploadField of Object.keys(formElement)) {
					await addFileUpload(
						formData,
						uploadField,
						formElement[uploadField],
						formElement,
					);
				}
			}
		}
	}
}

export async function addFileUploadsToFormData(formData: any) {
	for (const uploadField of Object.keys(formData).filter(
		(formField) =>
			formData[formField] instanceof FileList ||
			typeof formData[formField] === "object",
	)) {
		await addFileUpload(
			formData,
			uploadField,
			formData[uploadField],
			formData,
		);
	}
}

export const RenderApplicationFormDetailPart = (
	applicationForm: ApplicationForm,
	section: ApplicationFormSection,
	part: ApplicationFormSectionPart,
	register: UseFormRegister<FieldValues>,
	watch: UseFormWatch<FieldValues>,
	unregister: UseFormUnregister<FieldValues>,
	errors: { [x: string]: any },
	control: Control<FieldValues, any>,
	applicantData: Applicant,
	setValue: UseFormSetValue<FieldValues>,
	getValues: UseFormGetValues<FieldValues>,
	onSubmit: (formData: any) => Promise<void>,
	isReadOnly: boolean,
	isPostingForm?: boolean
) => {
	switch (part.code.toLocaleLowerCase()) {
		case "alertimmunization":
			return (
				applicantData.applicantProfile?.isImmunizationRequirementsComplete !== null && applicantData.applicantProfile?.isImmunizationRequirementsComplete === false ?
					<React.Fragment>
						<div className="alert alert-warning block py-2 px-2">{t('alert_immunization_incomplete')}</div>
					</React.Fragment>
				: null
			);
		case "tutorial":
			return (
					<RenderCollapse
						name="tutorial"
						title={t("tutorial")}
						subtitle={""}
						body={
							<ApplicationFormTutorial
								applicationFormId={applicationForm.uid}
								section={section}
							/>
						}
						collapsed={false}
					/>
			);
		case "staticform":
			return (
				part.param === "applicantform" ? (
					<>
					<ApplicantFormDetails applicationFormUid={applicationForm.uid} />
					<RenderCollapse
							name="applicantForm"
							title={t("student")}
							subtitle={""}
							body={
								<React.Fragment>
									<ApplicantForm
										applicantData={applicantData}
										applicationForm={applicationForm}
										errors={errors}
										register={register}
										watch={watch}
										isReadOnly={isReadOnly}
										setValue={setValue}
										control={control}
									/>
									{!isReadOnly ? sectionSaveButton("applicantForm", isPostingForm) : null}
								</React.Fragment>
							}
							collapsed={false}
						/>
						</>
					)
					: part.param === "applicantLGForm" ? (
						<RenderCollapse
							name="applicantLegalGuardianForm"
							title={t("legal_guardian")}
							subtitle={""}
							body={
								<React.Fragment>
									<ApplicantLegalGuardianForm
										setValue={setValue}
										control={control}
										applicantData={applicantData}
										errors={errors}
										register={register}
										unregister={unregister}
										watch={watch}
										isReadOnly={isReadOnly}
									/>
									{!isReadOnly ? sectionSaveButton("applicantLegalGuardianForm", isPostingForm) : null}
								</React.Fragment>
							}
							collapsed={false}
						/>
					)
					:
					part.param === "applicantSiblingsForm" ? (
						<RenderCollapse
							name="siblingForm"
							title={"Siblings"}
							subtitle={""}
							body={
								<React.Fragment>
									<AddNewFieldsRow
										applicantData={applicantData}
										disabled={isReadOnly}
										errors={errors}
										control={control}
										register={register}
									/>
									{!isReadOnly ? sectionSaveButton("siblingForm", isPostingForm) : null}
								</React.Fragment>
							}
							collapsed={false}
						/>
					)
					:
					part.param === "applicantEmergencyInfoForm" ? (
						<RenderCollapse
							name="applicantEmergencyInfoForm"
							title={t('emergency_info')}
							subtitle={t('over_25_not_live_with_parent')}
							body={
								<React.Fragment>
									<ApplicantEmergencyInfoForm
										applicantData={applicantData}
										errors={errors}
										register={register}
										isReadOnly={isReadOnly}
									/>
									{!isReadOnly ? sectionSaveButton("applicantEmergencyInfoForm", isPostingForm) : null}
								</React.Fragment>
							}
							collapsed={false}
						/>
					)
					:
					part.param === "applicantHobbiesForm" ? (
						<RenderCollapse
							name="applicantHobbiesForm"
							title={t("hobbies_and_interests")}
							subtitle={""}
							body={
								<React.Fragment>
									<ApplicantHobbiesForm
										applicantData={applicantData}
										setValue={setValue}
										errors={errors}
										register={register}
										isReadOnly={isReadOnly}
									/>
									{!isReadOnly ? sectionSaveButton("applicantHobbiesForm", isPostingForm) : null}
								</React.Fragment>
							}
							collapsed={false}
						/>
					)
					:
					part.param === "studentsletter" ? (
						<RenderCollapse
							name="studentsletter"
							title={t("student_letter")}
							subtitle={""}
							body={
								<LetterForm
									applicationFormId={applicationForm.uid}
									errors={errors}
									register={register}
									letterType={LetterTypeEnum.Applicant}
									isReadOnly={isReadOnly}
								/>
							}
							collapsed={false}
						/>
					) : part.param === "parentsletter" ? (
						<RenderCollapse
							name="applicantParentsLetter"
							title={t("parents_letter")}
							subtitle={""}
							body={
								<LetterForm
									applicationFormId={applicationForm.uid}
									errors={errors}
									register={register}
									letterType={LetterTypeEnum.Parent}
									isReadOnly={isReadOnly}
								/>
							}
							collapsed={false}
						/>
					) : part.param === "teacher" ? (
						<>
								<RenderCollapse
									name="applicantTeacherInformation"
									title={t("teacher_info")}
									subtitle={""}
									body={
										<React.Fragment>
											<ApplicantTeacherInfoForm
												applicationFormId={applicationForm.uid}
												errors={errors}
												register={register}
												control={control}
												isReadOnly={isReadOnly}
											/>
											{!isReadOnly ? sectionSaveButton("applicantTeacherInformation", isPostingForm) : null}
										</React.Fragment>
									}
									collapsed={false}
								/>
								<RenderCollapse
									name="applicantTeacher"
									title={t("teacher_recommendation")}
									subtitle={""}
									body={
										<React.Fragment>
											<ApplicantTeacherRecommendationForm
												errors={errors}
												register={register}
												isReadOnly={isReadOnly}
											/>
											{!isReadOnly ? sectionSaveButton("applicantTeacher", isPostingForm) : null}
										</React.Fragment>
									}
									collapsed={false}
								/>
						</>
					) : part.param === "languageteacher" ? (
						<>
								<RenderCollapse
									name="applicantLanguageTeacherInfo"
									title={t("language_teacher_info")}
									subtitle={""}
									body={
										<React.Fragment>
											<ApplicantLanguageTeacherInfoForm
												applicationFormId={applicationForm.uid}
												errors={errors}
												register={register}
												control={control}
												isReadOnly={isReadOnly}
											/>
											{!isReadOnly ? sectionSaveButton("applicantLanguageTeacherInfo", isPostingForm) : null}
										</React.Fragment>
									}
									collapsed={false}
								/>
								<RenderCollapse
									name="applicantLanguageTeacherRecommendation"
									title={t("language_teacher_recommendation")}
									subtitle={""}
									body={
										<React.Fragment>
											<ApplicantLanguageTeacherRecommendationForm
												applicationFormId={applicationForm.uid}
												errors={errors}
												register={register}
												isReadOnly={isReadOnly}
											/>
											{!isReadOnly ? sectionSaveButton("applicantLanguageTeacherRecommendation", isPostingForm) : null}
										</React.Fragment>
									}
									collapsed={false}
								/>
						</>
					) :
						part.param === "photoalbum" ?
							<PhotoAlbum
								applicationFormId={applicationForm.uid}
								register={register}
								getValues={getValues}
								onSubmit={onSubmit}
								isReadOnly={isReadOnly}
								setValue={setValue}
							/>
							:
							part.param === "agreement" ?
								<ApplicantAgreementForm
									applicationFormId={applicationForm.uid}
									applicationFormCultureCode={applicationForm.cultureCode}
									setValue={setValue}
									register={register}
									getValues={getValues}
									isReadOnly={isReadOnly}
									sectionId={section.id}
								/>
								: part.param === "schooltranscript" ?
									<>
										<RenderCollapse
											name="schoolinformation"
											title={t("school_info")}
											subtitle={""}
											body={
												<React.Fragment>
													<ApplicantSchoolInfoForm
														applicationForm={applicationForm}
														errors={errors}
														register={register}
														control={control}
														setValue={setValue}
														isReadOnly={isReadOnly}
													/>
													{!isReadOnly ? sectionSaveButton("schoolinformation", isPostingForm) : null}
												</React.Fragment>
											}
											collapsed={false}
											showPageBreak={false}
										/>
										<RenderCollapse
											name="schoolstranscript"
											title={t("school_transcript_last_3_years")}
											subtitle={""}
											body={
												<React.Fragment>
													<ApplicantSchoolTranscriptsForm
														errors={errors}
														unregister={unregister}
														register={register}
														watch={watch}
														control={control}
														nbRender={3}
														isReadOnly={isReadOnly}
														setValue={setValue}
													/>
													{!isReadOnly ? sectionSaveButton("schoolstranscript", isPostingForm) : null}
												</React.Fragment>
											}
											collapsed={false}
										/>
									</>
									: part.param === "physician" ?
										<>
											<RenderCollapse
												name="physicianinfos"
												title={t("physician_statement")}
												subtitle={""}
												body={
													<React.Fragment>
														<PhysicianInfos
															applicationFormId={applicationForm.uid}
															register={register}
															errors={errors}
															isReadOnly={isReadOnly}
														/>
														{!isReadOnly ? sectionSaveButton("physicianinfos", isPostingForm) : null}
													</React.Fragment>
												}
												collapsed={false}
											/>
										</>
										:
										part.param === "staffedit" ?
												<RenderCollapse
													name="staffedit"
													title={t("manage")}
													subtitle={""}
													body={
														<SectionStaffManagement
															applicationForm={applicationForm}
															section={section}
														/>
													}
													collapsed={false}
												/>
											:
											part.param === "applicationvalidationinfo" ?
													(
														applicationForm.statusOfValidator === ApplicationFormSectionStatusEnum.Approved ||
														applicationForm.statusOfValidator === ApplicationFormSectionStatusEnum.Incomplete
													)
													&&
													(
														applicationForm.submittedOn !== null
													)
													?
													<RenderCollapse
														name="applicantvalidationinfo"
														title={t("validation_info")}
														subtitle={""}
														body={
															<ApplicantFormValidationInfo
																applicationForm={applicationForm}
																section={section}
															/>
														}
														collapsed={false}
													/>
													: null
												: null
			);


		case "survey":
			if (part.param != null) {
				let surveyActions: string | null = null;
				let param = part.param;
				if (part.param.indexOf("?") > -1) {
					let arr = part.param.split("?");
					param = arr[0];
					surveyActions = arr[1];
				}
				if (surveyActions != null) {
					return (
						<>
							<RenderConditional
								surveyActionIds={surveyActions}
								body={
									<RenderCollapse
										name={param}
										title={part.name ?? ""}
										subtitle={""}
										body={
											<React.Fragment>
												<SurveyForm
													applicationFormId={applicationForm.uid}
													surveyCode={param}
													register={register}
													unregister={unregister}
													watch={watch}
													setValue={setValue}
													getValue={getValues}
													surveyActionsIds={surveyActions}
													isReadOnly={isReadOnly}
													control={control}
												/>
												{!isReadOnly ? sectionSaveButton(part.code + param, isPostingForm) : null}
											</React.Fragment>
										}
										collapsed={false}
									/>
								}
							/>
						</>
					);
				} else {
					return (
						<>
							<RenderCollapse
								name={param}
								title={part.name ?? ""}
								subtitle={""}
								body={
									<React.Fragment>
										<SurveyForm
											applicationFormId={applicationForm.uid}
											surveyCode={param}
											register={register}
											unregister={unregister}
											watch={watch}
											setValue={setValue}
											getValue={getValues}
											surveyActionsIds={surveyActions}
											isReadOnly={isReadOnly}
											control={control}
										/>
										{
											part.param === "PERS_HABITS" ?
												<ApplicantSmokerForm
													applicantData={applicantData}
													setValue={setValue}
													watch={watch}
													errors={errors}
													unregister={unregister}
													register={register}
													isReadOnly={isReadOnly}
												/>
												: null
										}
										{!isReadOnly ? sectionSaveButton(part.code + param, isPostingForm) : null}
									</React.Fragment>
								}
								collapsed={false}
							/>
						</>
					);
				}
			} else {
				return <></>;
			}
			;
	}
};

export const GetFinalStepData = (
	applicationForm: ApplicationForm,
	sections: ApplicationFormSection[],
): ApplicationFormSection | null => {
	const sectionsAmount = sections.length;

	if (sectionsAmount < 1) {
		return null;
	}

	const lastStep = sections[sectionsAmount - 1];

	let sectionStatus = ApplicationFormSectionStatusEnum.Empty;

	if(applicationForm.applicationFormStatus === ApplicationFormSectionStatusEnum.Approved)
	{
		sectionStatus = ApplicationFormSectionStatusEnum.Approved;
	}
	else if(applicationForm.applicationFormStatus === ApplicationFormSectionStatusEnum.Submitted)
	{
		sectionStatus = ApplicationFormSectionStatusEnum.Submitted;
	}

	return {
		id: lastStep.id + 1,
		label: t("finish"),
		imageUrl: "etape-13-send.png",
		orderNumber: lastStep.orderNumber + 1,
		validationStatus: sectionStatus,
		validationComment: null,
		uploadedFile: null,
		needSignatureInPDF: false,
		canReuploadSectionPDF: false,
		needStampInPDF: false,
		signatureLabelInPDF: null,
		stampLabelInPDF: null,
		isReuploadSectionPDFRequired: false,
		canDownloadPDFFromDashboard: false,
		statusColor: -1,
		statusOfValidator: 0,
		commentOfValidator: null,
		isReadOnly: false,
		isPDFDownloadable: false,
		isPDFFromExternalInfo: false,
	};
};

export const getCurrentApplicationFormSection = (applicationFormSections: ApplicationFormSection[]) => {
	let section: ApplicationFormSection | null = null;

	if (applicationFormSections && applicationFormSections.length > 0) {
		section = applicationFormSections.find(sec => getApplicationFormSectionUrl(sec).startsWith(pathname)) ?? null;
	}

	return section ?? null;
}

export const getApplicationFormSectionUrl = (
	section: ApplicationFormSection,
): string => {
	const label = section?.label?.replace("'", "") ?? "";
	return `${t("url_application_form_base")}${slugify(label, {
		lower: true,
		locale: "en",
	})}${window.location.search}`;
};

export const getApplicationFormFirstIncompleteSection = (applicationFormSections: ApplicationFormSection[]): ApplicationFormSection => {
	const incompleteSections = applicationFormSections.filter(section =>
		section.validationStatus === ApplicationFormSectionStatusEnum.Empty ||
		section.validationStatus === ApplicationFormSectionStatusEnum.InProgress ||
		section.validationStatus === ApplicationFormSectionStatusEnum.Incomplete
	);
	return incompleteSections.length > 0 ? incompleteSections[0] : applicationFormSections[0];
}


export const getApplicantFormApplicantName = (applicationForm: ApplicationForm) => {
	return applicationForm && applicationForm.firstName + " " + applicationForm.lastName
}

export const getApplicationFormIdFromUrl = (): string|null => {
	return GetUrlParams()["applicationFormId"] ?? null;
}

export 	const listApplicationForms = (applicationForm: ApplicationForm, userInfo: UserData | null) => {
	return(
	  <li key={applicationForm.uid} className="cursor-pointer list-group-item py-3 row d-flex g-0 align-items-center" 
	  onClick={() => !userInfo?.isSendingPartner && !userInfo?.isStaff ? redirectTo(t("url_dashboard") + (`?applicationFormId=${applicationForm.uid}`), false) : redirectTo( t('url_application_form_define') + (`?applicationFormId=${applicationForm.uid}`), false)}>
		<div className="col-md-9 col-sm-12">
			<div className="d-flex align-items-center">
				<h4 className="mb-0">{getApplicantFormApplicantName(applicationForm)} - {applicationForm.label}</h4> 
				{userInfo && badgeApprovedByWep(userInfo?.isSendingPartner, applicationForm)}
			</div>
			<small>{translateAppFormSubLabel(applicationForm, userInfo?.isStaff ?? false, userInfo?.isSendingPartner ?? false)}</small>
		</div>
		<div className="col-md-3 col-sm-12 align-items-center justify-content-end d-flex gotoapplicationform">
			<span className="goto mr-2">{t('go_to_application_form')}</span>
			<i className="icon-arrow-circle-right fontsize25"></i>
		</div>
	  </li>
	)
}

export function submitApplicationForm(applicationForm: ApplicationForm) {
	store.dispatch(postApplicationForm(applicationForm.uid));
}

export 	const getAllDataFromWep = (allData: any): SelectInterface[] => {
	if(!allData)
	{
		return [];
	}
	return allData.map((cu: { name: any; id: any; }) => {return {label: cu.name, value: cu.id} as SelectInterface})
}

export const convertToSelectInterface = (array: string[] | null) => {
	if(!array)
	{
		return [];
	}
	return array.map((a) => {return {label: a, value: a } as SelectInterface})
}


export 	const applicationFormSelect = (applicationForm: ApplicationForm, applicationForms: ApplicationForm[], setApplicationForm: (value: React.SetStateAction<ApplicationForm | null>) => void) => {
	return(
		<>
		{
			applicationForm && applicationForms && applicationForms.length > 1 ?
				<div className="pb-3">
					<ApplicationFormSelect
						applicationForms={applicationForms}
						currentApplicationForm={applicationForm}
						onChange={(newValue) => setApplicationForm(newValue)}
					/>
				</div>
			: ""
		}
		</>
	)
}

export const showSelectAppForm = (userInfo: UserData|null, selectedApplicationForm: ApplicationForm|null, applicationForms: ApplicationForm[]|null, setApplicationForm: (value: React.SetStateAction<ApplicationForm | null>) => void ) => {
	return(
		<>
		{
			userInfo ?

			!userInfo.isSendingPartner && !userInfo.isStaff ? 
				selectedApplicationForm && applicationForms ? 
					applicationFormSelect(selectedApplicationForm, applicationForms, setApplicationForm) 
				: null
			
			: 
				<div className="col-md-12 pb-3" onClick={() => redirectTo( t('url_application_form_define') + (`?applicationFormId=${selectedApplicationForm?.uid}`), false)}>
					<div className="link-to-app">
						<strong>{`${selectedApplicationForm?.firstName}` + " " + `${selectedApplicationForm?.lastName}`}</strong> {selectedApplicationForm?.label}	- {selectedApplicationForm ? translateAppFormSubLabel(selectedApplicationForm, userInfo?.isStaff ?? false, userInfo?.isSendingPartner ?? false) : ""}	
					</div>
				</div>
			: null
		}
		</>
	)
}

export const validateExtensionType = (e: React.ChangeEvent<HTMLInputElement>, accept:any, addErrorToaster:any, onFieldChange:any, id:any) => {
	e.preventDefault();
	const files = e.target.files;
	if (files && files.length > 0) {
	  const file = files[0];
	  const acceptedFormats = accept?.split(',').map((format: string) => format.trim().toLowerCase());
	  const fileExtension = file.name.split('.').pop()?.toLowerCase();
	  const fileType = file.type.toLowerCase();
		
	  let isAcceptedFormat = false;
		
	  if (acceptedFormats) {
		isAcceptedFormat = acceptedFormats.includes(fileType) || acceptedFormats.includes(`.${fileExtension}`);
	  }
		
	  if (!isAcceptedFormat) {
		addErrorToaster(t('invalid_format'));
		e.target.value = '';
		return;
	  }
  
	  if (onFieldChange) {
		onFieldChange(e, id);
	  }
	}
  };