import { RootState } from "app/RootReducer";
import { store } from "app/store";
import { renderCheckbox, renderEmailWithPattern, renderInputText } from "components/Forms/FormsComponent";
import SelectField from "components/Forms/SelectField";
import SubmitButton from "components/Forms/SubmitButton";
import { SpinnerOnTop } from "components/SpinnerOnTop";
import { genders, GetCurrentLocale, SelectInterface, customSelectStyles, FormatDateForUrl } from "components/Utils";
import { convertToSelectInterface } from "components/UtilsForApplicationForm";
import { ProgramTypeEnum } from "features/Entity/ApplicationForm";
import { DepartureDates, WEPProducts } from "features/Entity/WEPProducts";
import PageTitle from "features/Pages/PageTitle";
import { createApplicationForm } from "features/Slice/ApplicationFormSlice";
import { getApplicationFormTemplates } from "features/Slice/ApplicationFormTemplateSlice";
import { getWEPProducts, getWEPProductYears } from "features/Slice/CreateApplicationFormSlice";
import { getCultures } from "features/Slice/CultureSlice";
import React, { useMemo, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import t, { changeLanguage } from "../../../components/I18N/TranslateHelpers";
import Select from "react-select";
import { Modal } from '@mui/material';

const CreateApplicationForm: React.FC = () => {
	const currentProgamType = ProgramTypeEnum.Inbound;

	React.useEffect(() => {
		changeLanguage(GetCurrentLocale());

		store.dispatch(getCultures(true));
		store.dispatch(getApplicationFormTemplates(currentProgamType));
	}, []);

	const { register, formState: { errors }, handleSubmit, setValue } = useForm();

	const isPostingForm = useSelector((state: RootState) => state.applicationFormRdc.isRequestPost);
	const cultures = useSelector((State:RootState) => State.cultureRdc.cultures);
	const productYears = useSelector((State: RootState) => State.createApplicationFormRdc.productYears)
	const WEPProducts = useSelector((State: RootState) => State.createApplicationFormRdc.WEPProducts)
	const WEPProductsByCenter = WEPProducts && WEPProducts.filter((v,i,a)=>a.findIndex(v2=>(v2.CenterBOId===v.CenterBOId))===i)

	let WEPBOCenters: SelectInterface[] = []
	WEPProductsByCenter && WEPProductsByCenter.map((center) => {
		WEPBOCenters.push({label: center.CenterTitle, value: center.CenterBOId})
	})

	const isRequesting = useSelector((State: RootState) => State.createApplicationFormRdc.isRequesting)
	const isRequestingWEPProducts = useSelector((State:RootState) => State.createApplicationFormRdc.isRequestingWEPProducts)

	const [cultureCodeSelected, setCultureCodeSelected] = useState<SelectInterface|null>(null);
	const [yearOfDepartureSelected, setYearOfDepartureSelected] = useState<SelectInterface|null>(null);
	const [productNameSelected, setProductNameSelected] = useState<SelectInterface|null>(null);
	const [departureDateSelected, setDepartureDateSelected] = useState<SelectInterface|null>(null);
	const [durationSelected, setDurationSelected] = useState<SelectInterface|null>(null);
	const [centerBOIdSelected, setCenterBOIdSelected] = useState<SelectInterface|null>(null);
	const [applicationFormTemplateGuidSelected, setApplicationFormTemplateGuidSelected] = useState<SelectInterface|null>(null);
	const newAppFormGuid = useSelector((state: RootState) => state.applicationFormRdc.newAppFormGuid)

	const productYearsSelect = useMemo(() => {
		return convertToSelectInterface(productYears)
	}, [productYears]);

	let WEPProductsProductsName: string[]|null = []
	if(centerBOIdSelected){
		WEPProductsProductsName = WEPProducts && WEPProducts.filter((product) => product.CenterBOId === centerBOIdSelected.value).map((product) => product.ProductName)
	}

	const WEPProductsProductsNamesSelect = useMemo(() => {
		if(WEPProductsProductsName){
			return convertToSelectInterface(WEPProductsProductsName)
		}
	}, [WEPProductsProductsName]);

	let dateOfDepartureSelect: SelectInterface[] = []
	let durationsOfProgramSelected: SelectInterface[] = []
	const [concatDurations, setConcatDurations] = useState([])
	const [departureDates, setDepartureDates] = useState<DepartureDates[][]>([])
	const [externalProductId, setExternalProductId] = useState("")
	const [typeOfProduct, setTypeOfProduct] = useState("")
	const [destinationCountry, setDestinationCountry] = useState("")
	const [externalProductTitle, setExternalProductTitle] = useState("")

	React.useEffect(() => {
		if(productNameSelected){
			if(WEPProducts){
				const productselected = WEPProducts.filter((a) => a.ProductName === productNameSelected.label)
				const productId = productselected.map((product) => product.ProductId)
				const productType = productselected.map((product) => product.TypeOfProduct)
				const destinationCoun = productselected.map((product) => product.DestinationCountry)
				const externalProductIdSelected = productselected.map((product) => product.ProductName)
				setExternalProductId(productId.toString())
				setTypeOfProduct(productType.toString())
				setDestinationCountry(destinationCoun.toString())
				setExternalProductTitle(externalProductIdSelected.toString())
				const departureDateProductsSelected = productselected.map((product) => product.DepartureDates)
				setDepartureDates(departureDateProductsSelected)
			}
		}
	}, [productNameSelected]);

	React.useEffect(() => {
		if(departureDateSelected && productNameSelected){
			if(WEPProducts){
				const productselectedForDate = WEPProducts.find((a) => a.ProductName === productNameSelected.label)
				const departureDateProductsSelectedForDate = productselectedForDate?.DepartureDates.find((a) => a.DepartureDate === departureDateSelected.value)
				
				const durations = departureDateProductsSelectedForDate?.Durations
				const allDurations = durations?.map((a) => Object.values(a))

				//@ts-ignore
				setConcatDurations([].concat.apply([], allDurations))
			}
		}
	}, [departureDateSelected]);

	if(concatDurations.length > 0){
		durationsOfProgramSelected = convertToSelectInterface(concatDurations)
	}

	if(departureDates.length > 0){
		departureDates[0].map((item) => dateOfDepartureSelect.push({label : item.DepartureDate.split('T')[0].split('-').reverse().join('/'), value: item.DepartureDate}))
	}
	React.useEffect(() => {
		if(cultureCodeSelected){
			store.dispatch(getWEPProductYears(cultureCodeSelected.value))
		}
	}, [cultureCodeSelected]);

	React.useEffect(() => {
		if(yearOfDepartureSelected && cultureCodeSelected){
			store.dispatch(getWEPProducts(cultureCodeSelected.value, yearOfDepartureSelected.value))
		}
	}, [yearOfDepartureSelected]);


	const allWepCultures = useMemo(() => {
		if(!cultures)
		{
			return [];
		}
		return cultures.filter(cu => cu.isActive).map(cu => {return {label: cu.name, value: cu.code} as SelectInterface})
	}, [cultures]);

	const applicationFormTemplatesForSelect = useMemo(() => {
		if(productNameSelected){
			if(WEPProducts){
				const productselected = WEPProducts.find((a) => a.ProductName === productNameSelected.label)
				if(productselected) {
					return productselected.ApplicationFormTemplateDtos.map(a => {return {label: a.Label, value: a.MyWepUID} as SelectInterface})
				}
			}
		}
		return []
	}, [productNameSelected]);
	
    const [modalOpen, setModalOpen] = useState(false);
	function onSubmit(formValues: FieldValues)
	{
		formValues.programType = currentProgamType;
		formValues.externalProductId = parseInt(externalProductId);
		formValues.typeOfProduct = typeOfProduct;
		formValues.destinationCountry = destinationCountry;
		formValues.externalProductTitle = externalProductTitle;
		formValues.cultureCode = cultureCodeSelected && cultureCodeSelected.value;
		formValues.yearOfDeparture = yearOfDepartureSelected && yearOfDepartureSelected.value;
		formValues.departureDate = departureDateSelected && departureDateSelected.value;
		formValues.duration = durationSelected && durationSelected.value;
		formValues.productName = productNameSelected && productNameSelected.value
		formValues.applicationFormTemplateUID = applicationFormTemplateGuidSelected && applicationFormTemplateGuidSelected.value
		let isValid = true;
		const requiredFields = [
			"cultureCode",
			"yearOfDeparture",
			"departureDate",
			"productName",
			"applicationFormTemplateUID",
			"duration",
			"firstName",
			"lastName",
			"genderEnum",
			"email",
		];

		requiredFields.forEach(key => {
			if(formValues[key] === null || formValues[key] === undefined || (formValues[key] as string).length === 0)
			{
				isValid = false;
			}
		});

		if(isValid)
		{
			formValues.gender = formValues.genderEnum;
			formValues.genderEnum = parseInt(formValues.genderEnum);

			store.dispatch(createApplicationForm(formValues));
			setModalOpen(true)
		}
		else
		{
			toast.error(t('please_fill_in_required'));
		}
	}


	const changeData = (
		e:any, 
		setNewalue: (value: React.SetStateAction<SelectInterface | null>) => void, 
		currentValue: SelectInterface | null,
		changeOtherStates: (e: any) => void
		) => {
		setNewalue(e)
		if(currentValue){
			if(e !== null){
				if(e.label !== currentValue.label){
					changeOtherStates(e)
				}
			}
		}
	}

	const changeCultureCode = () => {
		setYearOfDepartureSelected(null)
		setProductNameSelected(null)
		setDepartureDateSelected(null)
		setDurationSelected(null)
		setCenterBOIdSelected(null)
		setApplicationFormTemplateGuidSelected(null)
	}

	const changeYearDeparture = () => {
		setProductNameSelected(null)
		setDepartureDateSelected(null)
		setDurationSelected(null)
		setCenterBOIdSelected(null)
		setApplicationFormTemplateGuidSelected(null)
	}

	const changeProductName = () => {
		setDepartureDateSelected(null)
		setDurationSelected(null)
		setApplicationFormTemplateGuidSelected(null)
	}

	const changeDepartureDate = () => {
		setDurationSelected(null)
	}

	const changeDuration = () => {

	}
	const changeCenterBOId = () => {
		setProductNameSelected(null)
		setDepartureDateSelected(null)
		setDurationSelected(null)
		setApplicationFormTemplateGuidSelected(null)
	}

	const modalForRedirect = () => {
        return(
			<Modal open={modalOpen}>
				<div className='card modal-dialog'>
					<div className="modal-header">
						<h5 className="modal-title" id="redirectModalTitle">{t('new_app_created')}</h5>
					</div>
					<div className='modal-body'>
					<div className="d-flex">
						<a href={t('url_application_form')} className='btn btn-primary-outline'>
							{t('go_to_app_forms_list')}
						</a>
						<a href={t('url_application_form_define')+"?applicationFormId=" + newAppFormGuid} className='btn btn-primary-outline ml-3'>
						{t('go_to_app_form')}
						</a>
					</div>
					</div>
				</div>
			</Modal>
		)
	}
	return (
		<>
			<PageTitle title={t('create_new_application_form')} />
			{modalForRedirect()}
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="row">
					<div className="col-md-8 col-sm-12">
						<div className="card">
							<div className="card-body">
								<div className="row">
									<div className="col-md-12">
										<h5 className="pb-3">{t('program_information')}</h5>
									</div>
									<div className="col-sm-12 col-md-6 mb-3 col-1024-6">
										<input type="hidden" name="externalProductId" value={externalProductId}/>
										<input type="hidden" name="typeOfProduct" value={typeOfProduct}/>
										<input type="hidden" name="destinationCountry" value={destinationCountry}/>
										<input type="hidden" name="externalProductTitle" value={externalProductTitle}/>
											<label className="col col-form-label required">
												{t('select_destination')}
											</label>
											<Select
												id="cultureCode"
												name="cultureCode"
												options={allWepCultures}
												defaultValue={null}
												value={cultureCodeSelected ?? ""}
												// @ts-ignore
												styles={customSelectStyles}
												onChange={(e: any) => {changeData(e, setCultureCodeSelected, cultureCodeSelected, changeCultureCode)}}
												isClearable={false}
											/>
									</div>
									{
										cultureCodeSelected ?
											isRequesting ? 
												<div className="small-spinner nomargin"><SpinnerOnTop/></div>
											:
												<div className="col-md-6 col-sm-12 mb-3 col-1024-6">
													<label className="col col-form-label required">
														{t('year_departure')}
													</label>
													<Select
														id="yearOfDeparture"
														name="yearOfDeparture"
														options={productYearsSelect}
														defaultValue={null}
														value={yearOfDepartureSelected ?? ""}
														// @ts-ignore
														styles={customSelectStyles}
														onChange={(e: any) => {changeData(e, setYearOfDepartureSelected, yearOfDepartureSelected, changeYearDeparture)}}
														isClearable={false}
													/>
												</div>
										: null
									}
									{
										cultureCodeSelected && yearOfDepartureSelected ?
										isRequestingWEPProducts ?
											<div className="small-spinner nomargin"><SpinnerOnTop/></div>
											:
											<div className="col-sm-12 col-md-6 mb-3 col-1024-6">
												<label className="col col-form-label required">
													{t('center')}
												</label>
												<Select
													name="centerBOId"
													options={WEPBOCenters}
													defaultValue={null}
													value={centerBOIdSelected ?? ""}
													// @ts-ignore
													styles={customSelectStyles}
													onChange={(e: any) => {changeData(e, setCenterBOIdSelected, centerBOIdSelected, changeCenterBOId)}}
													isClearable={false}>
												</Select>
											</div>
										: null
									}
									{
										cultureCodeSelected && yearOfDepartureSelected && centerBOIdSelected ?
												<div className="col-sm-12 col-md-6 mb-3 col-1024-6">
													<label className="col col-form-label required">
														{t('program')}
													</label>
													<Select
														id="productName"
														name="productName"
														options={WEPProductsProductsNamesSelect}
														defaultValue={null}
														value={productNameSelected ?? ""}
														// @ts-ignore
														styles={customSelectStyles}
														onChange={(e: any) => {changeData(e, setProductNameSelected, productNameSelected, changeProductName)}}
														isClearable={false}
													/>
												</div>
										: null
									}
									{
										cultureCodeSelected && yearOfDepartureSelected && productNameSelected && dateOfDepartureSelect ?
											<div className="col-sm-12 col-md-6 mb-3 col-1024-6">
												<div className="row align-items-end">
													<div className="col-md-7">
														<label className="col col-form-label required">
															{t('program_start_date')}
														</label>
														<Select
															name="departureDate"
															options={dateOfDepartureSelect}
															defaultValue={null}
															value={departureDateSelected ?? ""}
															// @ts-ignore
															styles={customSelectStyles}
															onChange={(e: any) => {changeData(e, setDepartureDateSelected, departureDateSelected, changeDepartureDate)}}
															isClearable={false}
														/>
													</div>
													<div className="col-md-5">
														<small className="pull-right alert alert-info block py-2 px-2">{t('generic_date')}</small>
													</div>
												</div>
											</div>
									: null
									}
									{
										cultureCodeSelected && yearOfDepartureSelected && productNameSelected && departureDateSelected ?
											<div className="col-sm-12 col-md-6 mb-3 col-1024-6">
												<label className="col col-form-label required">
													{t('duration')}
												</label>
												<Select
													name="duration"
													options={durationsOfProgramSelected}
													defaultValue={null}
													value={durationSelected ?? ""}
													// @ts-ignore
													styles={customSelectStyles}
													onChange={(e: any) => {changeData(e, setDurationSelected, durationSelected, changeDuration)}}
													isClearable={false}
												/>
											</div>
									: null
									}
									
									{
										cultureCodeSelected && yearOfDepartureSelected && productNameSelected && departureDateSelected && durationSelected ?
											<div className="col-sm-12 col-md-6 mb-3 col-1024-6">
												<label className="col col-form-label required">
													{t('application_form_template')}
												</label>
												<Select
													name="applicationFormTemplateUID"
													options={applicationFormTemplatesForSelect}
													defaultValue={null}
													value={applicationFormTemplateGuidSelected ?? ""}
													// @ts-ignore
													styles={customSelectStyles}
													onChange={(e: any) => {setApplicationFormTemplateGuidSelected(e)}}
													isClearable={false}
												/>
											</div>
										: null
									}
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-4 col-sm-12">
						<div className="card">
							<div className="card-body">
								<div className="row">
									<div className="col-md-12">
										<h5 className="pb-3">{t('participant')}</h5>
									</div>
									{renderInputText(
										t("first_name"),
										"firstName",
										"",
										"",
										register,
										true,
										"col-sm-12 col-md-12 mb-3 col-1024-6",
										false,
										false,
									)}
									{renderInputText(
										t("last_name"),
										"lastName",
										"",
										"",
										register,
										true,
										"col-sm-12 col-md-12 mb-3 col-1024-6",
										false,
										false,
									)}
									<SelectField
										classes="col-md-12 mb-3"
										name="genderEnum"
										options={genders}
										defaultValue={genders[0]}
										multiple={false}
										label={t('gender')}
										required={true}
										readOnly={false}
										customOnChange={undefined}
										setValue={setValue}
									/>
									{renderEmailWithPattern(
										t('email'),
										null,
										register,
										"email",
										true,
										errors.email,
										"col-sm-12 col-md-12 mb-3",
										undefined,
										false
									)}
									<div className="col-md-12 wepadmin-student">
										{
											renderCheckbox(
												t('send_account_activation_to_student'),
												`notifyUserOfAccount`,
												false,
												register,
												false,
												false
											)
										}
										<small className="alert alert-info block ml-3 py-2 px-2">
											{t('select_if_you_want_participant_complete_on_mywep')} <br />
											{t('you_can_active_later')}
										</small>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="form-group d-flex justify-content-end buttons-submit">
					{
						applicationFormTemplateGuidSelected ?
						<SubmitButton
							label={t("create_new")}
							isPostingForm={isPostingForm}
							buttonType="submit"
						/>
						: null
					}
					
				</div>
			</form>
		</>
	);
};

export default CreateApplicationForm;