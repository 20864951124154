import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AppThunk } from "../../app/store";
import { ApplicationForm, ApplicationFormSectionStatus, DashboardSectionTypeEnum } from "../Entity/ApplicationForm";
import { ApplicationFormSection, SectionFileUpload } from "../Entity/ApplicationFormSection";
import { getApplicationFormSectionsRep, getApplicationFormSectionPartsRep, getApplicationFormsRep, postApplicationFormSectionRep, getApplicationFormSectionStatusRep, postApplicationFormRep, postUpdateApplicationFormStatusRep, postApplicationFormSectionValidationStatusRep, uploadApplicationFormSectionFileRep, downloadApplicationFormSectionFileRep, getApplicationFormRep, createApplicationFormRep, getApplicationFormSectionStatusOfValidatorRep, getDashboardApplicationFormListRep, getCurrentAppFormRep, getApplicationFormsWithPaginationRep, generatePDFRep, getAppFormSectionPDFRep } from "../Repository/ApplicationFormRepository";
import { ApplicationFormSectionPart } from "features/Entity/ApplicationFormSectionPart";
import t from "../../components/I18N/TranslateHelpers";
import saveAs from "file-saver";
import { SectionStaffInfo } from "features/Entity/SectionStaffInfo";
import { AxiosError } from "axios";
import { redirectTo, storeToast, toastAxiosError } from "components/Utils";
import { ToastType } from "components/Toasts/ToastType";
import { getApplicationFormIdFromUrl, getApplicationFormSectionUrl } from "components/UtilsForApplicationForm";
import { Pager } from "features/Entity/Pager";

interface ApplicationFormState {
  isRequesting: boolean;
  isRequestingParts: boolean;
  isRequestingSectionStatus: boolean;
  isRequestingGetPDF: boolean;
  isRequestingSaveHtmlPDF: boolean;
  isRequestSuccess: boolean;
  isRequestPagerSuccess: boolean;
  isRequestPost: boolean;
  isRequestPostStaffSuccess: boolean;
  isRequestPostSectionPdf: boolean;
  isRequestPostSectionPdfSuccess: boolean;
  isRequestPostSuccess: boolean;
  isRequestApplicationFormPostSuccess: boolean;
  isRequestFailed: boolean;
  error: AxiosError | null;
  applicationForm: ApplicationForm | null;
  applicationForms: ApplicationForm[] | null;
  applicationFormSections: ApplicationFormSection[] | null;
  applicationFormSectionParts: ApplicationFormSectionPart[] | null;
  applicationFormSectionStatus: ApplicationFormSectionStatus | null;
  applicationFormSectionStatusOfValidator: ApplicationFormSectionStatus | null;
  applicationFormsInDashboard: {[index: string]: ApplicationForm[]};
  newAppFormGuid: string | null;
  applicationFormsPager: Pager<ApplicationForm> | null}

const initialState: ApplicationFormState = {
  isRequesting: false,
  isRequestingParts: false,
  isRequestingSectionStatus: false,
  isRequestingGetPDF: false,
  isRequestingSaveHtmlPDF: false,
  isRequestSuccess: false,
  isRequestPagerSuccess: false,
  isRequestApplicationFormPostSuccess: false,
  isRequestFailed: false,
  isRequestPost: false,
  isRequestPostStaffSuccess: false,
  isRequestPostSuccess: false,
  error: null,
  applicationForms: null,
  applicationForm: null,
  applicationFormSections: null,
  applicationFormSectionParts: null,
  applicationFormSectionStatus: null,
  applicationFormSectionStatusOfValidator: null,
  isRequestPostSectionPdf: false,
  isRequestPostSectionPdfSuccess: false,
  applicationFormsInDashboard: {},
  newAppFormGuid: null,
  applicationFormsPager: null}

function startRequestRdc(state: ApplicationFormState) {
  state.isRequesting = true;
  state.isRequestFailed = false;
  state.error = null;
  state.applicationFormSections = null;
  state.applicationFormSectionParts = null;
  state.applicationFormSectionStatus = null;
  state.applicationFormSectionStatusOfValidator = null;
}

function startRequestStepsRdc(state: ApplicationFormState) {
  state.isRequestingParts = true;
  state.isRequestFailed = false;
  state.error = null;
}

function startRequestSectionStatusRdc(state: ApplicationFormState) {
  state.isRequestingSectionStatus = true;
  state.isRequestFailed = false;
  state.error = null;
}

function startGetPDFRequestRdc(state: ApplicationFormState) {
  state.isRequestingGetPDF = true;
  state.isRequestFailed = false;
  state.error = null;
}

function successGetPDFRequestRdc(state: ApplicationFormState) {
  state.isRequestingGetPDF = false;
  state.isRequestFailed = false;
  state.isRequestSuccess = true;
  state.error = null;
}

function startSaveHtmlPDFRequestRdc(state: ApplicationFormState) {
  state.isRequestingSaveHtmlPDF = true;
  state.isRequestFailed = false;
  state.error = null;
}

function successSaveHtmlPDFRequestRdc(state: ApplicationFormState) {
  state.isRequestingSaveHtmlPDF = false;
  state.isRequestFailed = false;
  state.isRequestSuccess = true;
  state.error = null;
}

function requestFailedRdc(state: ApplicationFormState, action: PayloadAction<AxiosError>) {
  state.isRequesting = false;
  state.isRequestSuccess = false;
  state.isRequestFailed = true;
  state.isRequestingParts = false;
  state.isRequestingSectionStatus = false;
  state.isRequestingGetPDF = false;
  state.isRequestApplicationFormPostSuccess = false;
  state.isRequestPost = false;
  state.isRequestPostStaffSuccess = false;
  state.isRequestPostSuccess = false;
  state.error = action.payload;
}

function requestApplicationFormsSuccessRdc(state: ApplicationFormState, action: PayloadAction<ApplicationForm[]>) {
  state.isRequesting = false;
  state.isRequestSuccess = true;
  state.isRequestFailed = false;
  state.error = null;
  state.applicationFormSections = null;
  state.applicationForms = action.payload;
}

function requestApplicationFormsInDashboardSuccessRdc(state: ApplicationFormState, action: PayloadAction<{appforms: ApplicationForm[], index: string}>) {
  state.isRequesting = false;
  state.applicationFormsInDashboard[action.payload.index] = action.payload.appforms;
}
function requestApplicationFormsWithPaginationSuccessRdc(state: ApplicationFormState, action: PayloadAction<Pager<ApplicationForm>>) {
  state.isRequesting = false;
  state.isRequestSuccess = false;
  state.isRequestPagerSuccess = true;
  state.isRequestFailed = false;
  state.error = null;
  state.applicationFormSections = null;
  state.applicationForms = null;
  state.applicationFormsPager = action.payload;
}function requestApplicationFormSuccessRdc(state: ApplicationFormState, action: PayloadAction<ApplicationForm>) {
  state.isRequesting = false;
  state.isRequestSuccess = true;
  state.isRequestFailed = false;
  state.error = null;
  state.applicationFormSections = null;
  state.applicationForm = action.payload;
}

function requestSuccessRdc(state: ApplicationFormState, action: PayloadAction<any>) {
  state.isRequesting = false;
  state.isRequestingParts = false;
  state.isRequestingGetPDF = false;
  state.isRequestSuccess = true;
  state.isRequestFailed = false;
  state.error = null;
}

function requestSectionsSuccessRdc(state: ApplicationFormState, action: PayloadAction<ApplicationFormSection[]>) {
  state.isRequesting = false;
  state.isRequestSuccess = true;
  state.isRequestFailed = false;
  state.error = null;
  state.applicationFormSections = action.payload;
}

function requestSectionStatusSuccessRdc(state: ApplicationFormState, action: PayloadAction<ApplicationFormSectionStatus>) {
  state.isRequestingSectionStatus = false;
  state.isRequestSuccess = true;
  state.isRequestFailed = false;
  state.error = null;
  state.applicationFormSectionStatus = action.payload;
}

function requestSectionStatusOfValidatorSuccessRdc(state: ApplicationFormState, action: PayloadAction<ApplicationFormSectionStatus>) {
  state.isRequestingSectionStatus = false;
  state.isRequestSuccess = true;
  state.isRequestFailed = false;
  state.error = null;
  state.applicationFormSectionStatusOfValidator = action.payload;
}

function requestSectionStepsSuccessRdc(state: ApplicationFormState, action: PayloadAction<ApplicationFormSectionPart[]>) {
  state.isRequesting = false;
  state.isRequestingParts = false;
  state.isRequestSuccess = true;
  state.isRequestFailed = false;
  state.error = null;
  state.applicationFormSectionParts = action.payload;
}

function startPostRequestRdc(state: ApplicationFormState) {
  state.isRequestPost = true;
  state.isRequestPostSuccess = false;
}

function resetPostRequestRdc(state: ApplicationFormState) {
  state.isRequestPost = false;
  state.isRequestPostSuccess = false;
}

function requestPostSuccessRdc(state: ApplicationFormState) {
  state.isRequestPost = false;
  state.isRequestPostSuccess = true;
}

function requestPostSectionPdfRdc(state: ApplicationFormState) {
  state.isRequestPostSectionPdf = true;
  state.isRequestPostSectionPdfSuccess = false;
}

function requestPostSectionPdfSuccessRdc(state: ApplicationFormState) {
  state.isRequestPostSectionPdf = false;
  state.isRequestPostSectionPdfSuccess = true;
}

function requestApplicationFormPostSuccessRdc(state: ApplicationFormState) {
  state.isRequestPost = false;
  state.isRequestApplicationFormPostSuccess = true;
}
function requestNewApplicationFormPostSuccessRdc(state: ApplicationFormState, action: PayloadAction<string>) {
  state.isRequestPost = false;
  state.isRequestApplicationFormPostSuccess = true;
  state.newAppFormGuid = action.payload
}

const ApplicationFormSlice = createSlice({
  name: 'ApplicationForm',
  initialState: initialState,
  reducers: {
    startRequestAct: startRequestRdc,
    startRequestStepsAct: startRequestStepsRdc,
    requestSuccessAct: requestSuccessRdc,
    requestFailedAct: requestFailedRdc,
    requestSectionsSuccessAct: requestSectionsSuccessRdc,
    requestApplicationFormsSuccessAct: requestApplicationFormsSuccessRdc,
    requestApplicationFormsWithPaginationSuccessAct: requestApplicationFormsWithPaginationSuccessRdc,
    requestApplicationFormSuccessAct: requestApplicationFormSuccessRdc,
    requestSectionStepsSuccessAct: requestSectionStepsSuccessRdc,
    startPostRequestAct: startPostRequestRdc,
    requestPostSuccessAct: requestPostSuccessRdc,
    startRequestSectionStatusAct: startRequestSectionStatusRdc,
    requestSectionStatusSuccessAct: requestSectionStatusSuccessRdc,
    requestSectionStatusOfValidatorSuccessAct: requestSectionStatusOfValidatorSuccessRdc,
    resetPostRequestAct: resetPostRequestRdc,
    requestApplicationFormPostSuccessAct: requestApplicationFormPostSuccessRdc,
    requestNewApplicationFormPostSuccessAct: requestNewApplicationFormPostSuccessRdc,
    startGetPDFRequestAct: startGetPDFRequestRdc,
    successGetPDFRequestAct: successGetPDFRequestRdc,
    requestPostSectionPdfAct: requestPostSectionPdfRdc,
    requestPostSectionPdfSuccessAct: requestPostSectionPdfSuccessRdc,
    startSaveHtmlPDFRequestAct: startSaveHtmlPDFRequestRdc,
    successSaveHtmlPDFRequestAct: successSaveHtmlPDFRequestRdc,
    requestApplicationFormsInDashboardSuccessAct: requestApplicationFormsInDashboardSuccessRdc,
  }
}
)

export const {
  startRequestAct,
  startRequestStepsAct,
  requestSuccessAct,
  requestFailedAct,
  requestSectionsSuccessAct,
  requestApplicationFormsSuccessAct,
  requestApplicationFormsWithPaginationSuccessAct,
  requestApplicationFormSuccessAct,
  requestSectionStepsSuccessAct,
  startPostRequestAct,
  requestPostSuccessAct,
  startRequestSectionStatusAct,
  requestSectionStatusSuccessAct,
  requestSectionStatusOfValidatorSuccessAct,
  resetPostRequestAct,
  requestApplicationFormPostSuccessAct,
  requestNewApplicationFormPostSuccessAct,
  startGetPDFRequestAct,
  successGetPDFRequestAct,
  requestPostSectionPdfAct,
  requestPostSectionPdfSuccessAct,
  startSaveHtmlPDFRequestAct,
  successSaveHtmlPDFRequestAct,
  requestApplicationFormsInDashboardSuccessAct,
} = ApplicationFormSlice.actions;

export default ApplicationFormSlice.reducer;

export const getApplicationForms = (): AppThunk => async dispatch => {
  try {
    dispatch(startRequestAct());

    const apiResponse = await getApplicationFormsRep();
    dispatch(requestApplicationFormsSuccessAct(apiResponse));
  } catch (err) {
    dispatch(requestFailedAct(err as AxiosError));
  }
}

export const getApplicationFormsWithPagination = (limit: number, page: number): AppThunk => async dispatch => {
  try {
    dispatch(startRequestAct());
    const apiResponse = await getApplicationFormsWithPaginationRep(limit, page);
    dispatch(requestApplicationFormsWithPaginationSuccessAct(apiResponse));
  } catch (err) {
    dispatch(requestFailedAct(err as AxiosError));
  }
}

export const getCurrentAppForm = (uid: string|null = null, reloaded= false): AppThunk => async dispatch => {
  try {
    uid = uid ?? getApplicationFormIdFromUrl()

    if (!reloaded) {
      dispatch(startRequestAct());
    }

    const apiResponse = await getCurrentAppFormRep(uid);
    dispatch(requestApplicationFormSuccessAct(apiResponse));

  } catch (err) {
    toastAxiosError(err as AxiosError);
    dispatch(requestFailedAct(err as AxiosError));
  }
}

export const getApplicationFormSections = (applicationForm: ApplicationForm, isReposted = false): AppThunk => async dispatch => {
  try {
    if (isReposted) {
      dispatch(startRequestAct());
    }

    const apiResponse = await getApplicationFormSectionsRep(applicationForm);
    dispatch(requestSectionsSuccessAct(apiResponse));
  } catch (err) {
    dispatch(requestFailedAct(err as AxiosError));
  }
}

export const getApplicationFormSectionsAndRedirectToFirst = (applicationForm: ApplicationForm): AppThunk => async dispatch => {
  try {
    dispatch(startRequestAct());
    const apiResponse = await getApplicationFormSectionsRep(applicationForm);

    if (apiResponse && apiResponse.length > 0) {
			redirectTo(getApplicationFormSectionUrl(apiResponse[0]));
		}
    else
    {
      dispatch(requestSectionsSuccessAct(apiResponse));
    }
  } catch (err) {
    dispatch(requestFailedAct(err as AxiosError));
  }
}

export const resetApplicationFormPosted = (): AppThunk => async dispatch => {
  dispatch(resetPostRequestAct());
}

export const getApplicationFormSectionParts = (applicationFormUid: string, applicationFormSectionId: number): AppThunk => async dispatch => {
  try {
    dispatch(startRequestStepsAct());
    const apiResponse = await getApplicationFormSectionPartsRep(applicationFormUid, applicationFormSectionId);
    dispatch(requestSectionStepsSuccessAct(apiResponse));
  } catch (err) {
    dispatch(requestFailedAct(err as AxiosError));
  }
}

export const postApplicationFormSection = (applicationFormUid: string, sectionId: number, formValues: any): AppThunk => async dispatch => {
  try {
    dispatch(startPostRequestAct());
    await postApplicationFormSectionRep(applicationFormUid, sectionId, formValues);
    dispatch(requestPostSuccessAct());
    storeToast(ToastType.SUCCESS, "data_successfully_updated");
  } catch (err) {
    toastAxiosError(err as AxiosError);
    dispatch(requestFailedAct(err as AxiosError));
  }
}

export const getApplicationFormSectionStatus = (applicationFormUid: string): AppThunk => async dispatch => {
  try {
    dispatch(startRequestSectionStatusAct());
    const apiResponse = await getApplicationFormSectionStatusRep(applicationFormUid);
    dispatch(requestSectionStatusSuccessAct(apiResponse));
  } catch (err) {
    dispatch(requestFailedAct(err as AxiosError));
  }
}

export const getApplicationFormSectionStatusOfValidator = (applicationFormUid: string): AppThunk => async dispatch => {
  try {
    dispatch(startRequestSectionStatusAct());
    const apiResponse = await getApplicationFormSectionStatusOfValidatorRep(applicationFormUid);
    dispatch(requestSectionStatusOfValidatorSuccessAct(apiResponse))
  } catch (err) {
    dispatch(requestFailedAct(err as AxiosError));
  }
}
export const postApplicationFormSectionValidationStatus = (formData: SectionStaffInfo, applicationFormUid: string): AppThunk => async dispatch => {
  try {
    dispatch(startPostRequestAct());
    await postApplicationFormSectionValidationStatusRep(formData, applicationFormUid);
    dispatch(requestPostSuccessAct());
    toast.success(t("application_form_successfully_updated"));
  } catch (err) {
    toastAxiosError(err as AxiosError);
    dispatch(requestFailedAct(err as AxiosError));
  }
}

export const getAppFormSectionPDF = (uid:string, sectionId: number,isAnonymous : boolean,isEmpty: boolean, sectionSlug: string): AppThunk => async dispatch => {
  try {
    dispatch(startGetPDFRequestAct());
    const apiResponse = await getAppFormSectionPDFRep(uid, sectionId, isAnonymous, isEmpty,sectionSlug);
    saveAs(apiResponse.fileBlob, apiResponse.filename);
    dispatch(successGetPDFRequestAct())
  } catch (err: any) {
    dispatch(requestFailedAct(err as AxiosError));
  }
}

export const postApplicationForm = (applicationFormUid: string): AppThunk => async dispatch => {
  try {
    dispatch(startPostRequestAct());
    await postApplicationFormRep(applicationFormUid);
    dispatch(requestApplicationFormPostSuccessAct());
    toast.success(t("application_form_successfully_submitted"));
  } catch (err) {
    toastAxiosError(err as AxiosError);
    dispatch(requestFailedAct(err as AxiosError));
  }
}

export const postApplicationFormStaffStatus = (formData: {}): AppThunk => async dispatch => {
  try {
    dispatch(startPostRequestAct());
    await postUpdateApplicationFormStatusRep(formData);
    dispatch(requestApplicationFormPostSuccessAct());
    toast.success(t("application_form_successfully_updated"));
  } catch (err) {
    toastAxiosError(err as AxiosError);
    dispatch(requestFailedAct(err as AxiosError));
  }
}

export const uploadApplicationFormSectionFile = (formData: SectionFileUpload): AppThunk => async dispatch => {
  try {
    dispatch(requestPostSectionPdfAct());
    await uploadApplicationFormSectionFileRep(formData);
    dispatch(requestPostSectionPdfSuccessAct());
    toast.success(t("file_successfully_uploaded"));
  } catch (err) {
    toastAxiosError(err as AxiosError);
    dispatch(requestFailedAct(err as AxiosError));
  }
}

export const downloadApplicationFormSectionFile = (applicationFormUid: string, sectionId: number, filename: string): AppThunk => async dispatch => {
  try {
    const apiResponse = await downloadApplicationFormSectionFileRep(applicationFormUid, sectionId);

    saveAs(apiResponse, filename);
  } catch (err) {
    toastAxiosError(err as AxiosError);
    dispatch(requestFailedAct(err as AxiosError));
  }
}

export const createApplicationForm = (formValues: {}): AppThunk => async dispatch => {
  try {
    dispatch(startPostRequestAct());
    const appFormGuid = await createApplicationFormRep(formValues);
    dispatch(requestNewApplicationFormPostSuccessAct(appFormGuid));
    toast.success(t("application_form_successfully_created"));

    // redirectTo(`${t('url_application_form_define')}?applicationFormId=${appFormGuid}`, false, 1000);
  } catch (err) {
    toastAxiosError(err as AxiosError);
    dispatch(requestFailedAct(err as AxiosError));
  }
}

export const getDashboardApplicationFormList = (year: number, listName: string, departurePeriod: string|null, sectionType: DashboardSectionTypeEnum, indexName: string): AppThunk => async dispatch => {
  try {
    dispatch(startRequestAct());

    const apiResponse = await getDashboardApplicationFormListRep(year, listName, departurePeriod, sectionType);

    dispatch(requestApplicationFormsInDashboardSuccessAct({appforms: apiResponse, index: indexName}));
  } catch (err) {
    dispatch(requestFailedAct(err as AxiosError));
  }
}

export const generatePDF = (sectionId: number, uid: string, isAnonymous: boolean, isEmpty: boolean): AppThunk => async dispatch => {
  try {
    const apiResponse = await generatePDFRep(sectionId, uid, isAnonymous, isEmpty);
    saveAs(apiResponse.fileBlob, apiResponse.filename);
  } catch (err: any) {
    dispatch(requestFailedAct(err as AxiosError));
  }
}