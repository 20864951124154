import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState, store } from "../../app/store";
import { changeLanguage } from "../../components/I18N/TranslateHelpers";
import { SpinnerOnTop } from "../../components/SpinnerOnTop";
import { GetCurrentLocale } from "../../components/Utils";
import { getCurrentApplicationFormSection } from "../../components/UtilsForApplicationForm";
import { ApplicationFormSection } from "../Entity/ApplicationFormSection";
import PageTitle from "../Pages/PageTitle";
import { getApplicationFormSections, getCurrentAppForm } from "../Slice/ApplicationFormSlice";
import ApplicationFormSectionDetails from "./ApplicationFormSectionDetails";
import ApplicationFormSections from "./ApplicationFormSections";

const ApplicationFormContainer: React.FC = () => {
	const isRequesting = useSelector((state: RootState) => state.applicationFormRdc.isRequesting);
	const sections = useSelector((state: RootState) => state.applicationFormRdc.applicationFormSections);
	const isRequestingUserInfo = useSelector((state: RootState) => state.userRdc.isRequesting);
	const userInfo = useSelector((state: RootState) => state.userRdc.userInfo);

	const [selectedApplicationFormSection, setSelectedApplicationFormSection] = useState<ApplicationFormSection|null>(null);
	const [nextApplicationFormSection, setNextApplicationFormSection] = useState<ApplicationFormSection|null>(null);
	const currentAppForm = useSelector((state: RootState) => state.applicationFormRdc.applicationForm);
	
	React.useEffect(() => {
		const locale = GetCurrentLocale();
		changeLanguage(locale);

		if(!isRequesting){
			store.dispatch(getCurrentAppForm());
		}
	}, []);

	React.useEffect(() => {
		if(currentAppForm && !isRequesting)
		{
			store.dispatch(getApplicationFormSections(currentAppForm));
		}
	}, [currentAppForm]);

	React.useEffect(() => {
		if(sections)
		{
			const selectedSection = sections ? getCurrentApplicationFormSection(sections) : null;
			setSelectedApplicationFormSection(selectedSection);
			setNextApplicationFormSection(selectedSection ? sections.find(section => section.orderNumber === selectedSection.orderNumber + 1) ?? null : null);
		}
	}, [sections]);

	return (
		<React.Fragment>
			<PageTitle title={selectedApplicationFormSection?.label ?? ""}/>
			<ApplicationFormSections/>
			{
				isRequesting || isRequestingUserInfo ?
					<SpinnerOnTop/>
				:
				(selectedApplicationFormSection && currentAppForm && userInfo) ?
					<ApplicationFormSectionDetails
						key={currentAppForm.uid}
						applicationForm={currentAppForm}
						applicationFormSection={selectedApplicationFormSection}
						nextSection={nextApplicationFormSection}
						isReadOnly={selectedApplicationFormSection.isReadOnly}
					/>
				:""
			}
		</React.Fragment>
	);
};

export default ApplicationFormContainer;
